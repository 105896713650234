import React from 'react'

export default function ChatIcon() {
      return (
            <>
                  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14" fill="none">
                        <path d="M4.16243 5.56231H4.25668M7 5.56231H7.09023M9.84023 5.56231H9.92645M1.5 1H12.5V10H7L4.25 13.0002V10H1.5V1Z" stroke="black" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="bevel" />
                  </svg>
            </>
      )
}