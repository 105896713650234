import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";

export default function UITeatoxLife() {

    return (
        <>
            <Helmet>
                <title>Teatox UI/UX Design Portfolio | Herbal Tea Brand UI Design | aBox Agency</title>
                <meta name="title" content="Teatox UI/UX Design Portfolio | Herbal Tea Brand UI Design | aBox Agency" />
                <meta name="description" content="Explore our UI/UX design portfolio featuring our work with TeaTox, a globally recognized brand in the herbal and wellness tea industry. We’ve created an engaging, user-friendly e-commerce interface that reflects the brand’s identity and enhances user interaction." />
                <meta name="keywords" content="UI Design, UX Design, CRO, Graphics Design, Herbal Teas, Wellness Teas, eCommerce, TeaTox, aBox Agency, aBox, web design, Shopify UI, Best UI design, UIUX Design, aBox UIUX services, User Interface, User Experience, Conversion Rate Optimization, Graphic Design, Web Design, Portfolio, Design Agency, Web Agency, International Brand, User-Friendly, Engaging Design, Brand Identity, User Interaction, E-commerce Design, Health and Wellness, Herbal Products, Wellness Products, Mobile App Design, User-Centered Design, Interaction Design, Responsive Design, Information Architecture, Wireframing, Prototyping, Visual Design, User Research, User Testing, Design Systems, Web Development, Frontend Development, SEO, Digital Marketing, E-commerce Development, Website Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ui/teatox-life/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title teatox_title mb-0"><span >TeaTox Life</span> <br /> <span className="text_black">Designing Wellness: <br /> Crafting Health Through Design</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product_banner">
                    <img src="/assets/image/ui/teatoxlife/teatox1.webp"
                        alt="teatox life" className="img-fluid w-100" />
                </div>

                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Teatox is a globally recognized brand specializing in herbal teas for everyday use. So  we’ve crafted a unique user interface that encapsulates the essence of the Teatox brand with a touch of international flair. Given that Teatox operates in the e-commerce space, we’ve prioritized user-friendliness, creating an engaging and intuitive experience for all users. This design not only reflects the brand’s identity but also enhances user interaction, making every visit to the Teatox website a delightful experience.</p>
                                <ul className="official_web">
                                    <li>
                                        <a href="https://herbalhermit.com/" target="_blank" className="d-flex align-items-center contact_light">Official Website
                                            <SiteLink />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>UI Design</li>
                                            <li>UX Design</li>
                                            <li>CRO</li>
                                            <li>Graphics Design</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Herbal and wellness teas</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui_section">
                    <div className="container">
                        <img src="/assets/image/ui/teatoxlife/teatox2.webp"
                            alt="teatox life" className="img-fluid w-100" />

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/ui/teatoxlife/teatox3.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/ui/teatoxlife/teatox4.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/ui/teatoxlife/teatox5.webp" className="w-100" alt="teatox life" />
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/ui/teatoxlife/teatox6.webp" className="w-100" alt="teatox life" />
                        </div>

                        <div className="row mt_30">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/ui/teatoxlife/teatox7.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/ui/teatoxlife/teatox8.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30 mb-4">
                            <img src="/assets/image/ui/teatoxlife/teatox9.webp" className="w-100" alt="teatox life" />
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}