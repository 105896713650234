import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelCocktails() {

      return (
            <>
                  <Helmet>
                        <title>Realistic 3D Cocktails Bottle Design | aBox Agency</title>
                        <meta name="title" content="Realistic 3D Cocktails Bottle Design | aBox Agency" />
                        <meta name="description" content="Abox Agency created lifelike 3D renders and mockups for Pourd Cocktails, showcasing their premium cocktail bottles with vibrant textures and sophisticated designs." />
                        <meta name="keywords" content="3D rendering, product modelling, cocktail bottle design, realistic 3D mockups, premium cocktail packaging, drink packaging design, e-commerce product visuals, product visualisation, luxury cocktails, 3D product design, beverage mockups, product showcase, digital presentation, realistic renders, premium drink bottles, beverage branding, cocktail brand identity, creative packaging design, luxury drinks, 3D product showcase, high-quality renders, drink mix packaging, e-commerce design, drink visuals, luxury brand designs, premium beverage renders, realistic bottle design, product mockup, beverage industry." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/cocktails/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Pour’d</span> <br /> <span className="text_black">Sculpting Luxury <br/> Cocktails in 3D</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/cocktails/cocktails1.jpg"
                                    alt="Cocktails" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Pourd Cocktails offers premium cocktail mixes, and we collaborated with them to create realistic 3D renders and mockups for their cocktail bottles. Our objective was to bring their premium products to life digitally, showcasing the fine craftsmanship and sophistication of their brand. The brand required lifelike 3D models to elevate their online presence and appeal to their target audience, making their cocktail bottles stand out in a competitive market.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Bottle Visualization</li>
                                                                  <li>Texturing and Lighting</li>
                                                                  <li>Product Mockups</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Beverages & Cocktails</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="mt_30">
                                          <img src="/assets/image/label/cocktails/cocktails2.jpg" alt="Cocktails" className="img-fluid w-100" />
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/cocktails/cocktails3.jpg" className="w-100" alt="Cocktails" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/cocktails/cocktails4.jpg" className="w-100" alt="Cocktails" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/cocktails/cocktails5.jpg" className="w-100" alt="Cocktails" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <video className="ras_video2 h-100" width={"100%"} autoPlay loop muted playsInline>
                                                      <source src="https://d1cihhbb125h48.cloudfront.net/1736423562.mp4" type="video/mp4" />
                                                </video>
                                          </div>
                                    </div> 
                                    <div className="content_space">
                                          <p className="services_content text_black">We crafted detailed 3D models of the cocktail bottles, paying close attention to textures, lighting, and material details. With realistic rendering and mockups, we ensured that the final presentation captured the luxury and elegance of their cocktails. Our work enhanced their digital presence, creating stunning visuals for various online platforms and making Pourd Cocktails shine.</p>
                                    </div>                                                                      
                                    <div>
                                          <img src="/assets/image/label/cocktails/cocktails6.jpg" alt="Cocktails" className="img-fluid w-100" />
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4 mb-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/cocktails/cocktails7.jpg" className="w-100" alt="Cocktails" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/cocktails/cocktails8.jpg" className="w-100" alt="Cocktails" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}