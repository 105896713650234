import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceSEO() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '170vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>SEO For Online Presence | Expert SEO Solutions | aBox Agency</title>
                <meta name="title" content="SEO For Online Presence | Expert SEO Solutions | aBox Agency" />
                <meta name="description" content=" Elevate your online presence with expert SEO solutions from aBox Agency. Discover how our SEO services can enhance your website's visibility and drive organic growth." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, SEO For Online Presence, Expert SEO Solutions, aBox Agency, Search Engine Optimization, Organic Growth, Website Visibility, SEO Services, SEO Strategy, Web Agency Services, Improve Search Ranking, Online Visibility, SEO Techniques, Digital Marketing, Web Optimization, SEO Excellence, Boost Organic Traffic, SEO Expertise, Effective SEO, Web Presence Enhancement, SEO Campaign, SEO Consultation, Increase Website Traffic, SEO Best Practices, Web Agency SEO, Search Engine Success" />
                <link rel="canonical" href="https://www.abox.agency/services/seo/" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding">
                                <h1 className="service_heading">SEO Services</h1>
                                <p className="seo_p">Unlock your website's potential with aBox Agency's Onsite SEO! Boost rankings, attract targeted traffic, and enhance the user experience. We optimize meta tags, content, and site structure for optimal search engine visibility. Stay ahead of the competition and watch your online presence flourish. Elevate your website today!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/seo.webp" alt="seo" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Unveiling the Power of Proven Scientific Search Engine Optimization</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Experience a transformational impact on your website by engineering it to ascend search engine rankings and attract organic leads. Utilize keyword research, content optimization, and advanced technical enhancements to conquer the digital realm.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">Keyword Research</p>
                                <p className="feature_p">Meta Tags Optimization</p>
                                <p className="feature_p">URL Structure</p>
                                <p className="feature_p">Content Optimization</p>
                                <p className="feature_p">Internal Linking</p>
                                <p className="feature_p">Image Optimization</p>
                                <p className="feature_p">Page Load Speed</p>
                                <p className="feature_p">Mobile-Friendly Design</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why only us?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Keyword Strategy</h3>
                                    <p className="seo_content mb-0 font_light">A successful keyword strategy requires understanding your enterprise and user habits and integrating your offerings with online search queries. Our expert strategists analyze monthly volume trends, competitor analysis, and SERP scrutiny to prioritize effectively, resulting in a laser-focused keyword blueprint for successful SEO endeavors.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Peer into the Competitive Landscape</h3>
                                    <p className="seo_content mb-0 font_light">Conducting competitor SEO analysis helps maintain a competitive edge in the digital battleground by understanding their ranking trajectory, performance indicators like backlinks, domain authority, and estimated traffic. This insight empowers businesses to stay ahead in the market.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Unleash the Power of Content Analysis</h3>
                                    <p className="seo_content mb-0 font_light">Experience high-quality content with our SEO services, creating engaging content with targeted keywords and a strategic vision. As creative partners, elevate your online presence with captivating content.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Reporting</h3>
                                    <p className="seo_content mb-0 font_light">SEO reporting provides in-depth analysis, performance indicators, growth metrics, and actionable insights for digital endeavors. It helps uncover strategies' impact, track campaign progress, and make informed decisions, maximizing the potential for triumph in the digital landscape.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* detail section */}
            <div className="section_detail image_section detail_section seodetail" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Boost your search <br className="d-md-block d-none" />
                                rankings with Onsite SEO expertise.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Keyword Research and Analysis </h3>
                                    <p className="services_p">The first step in our SEO services is thorough keyword research and analysis. We identify relevant keywords and phrases that your target audience is using to search for products or services similar to yours. By understanding the search intent behind these keywords, we can optimize your website's content accordingly, ensuring it aligns with what potential customers are looking for.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">On-Page Optimization</h3>
                                    <p className="services_p">Enhance your website's ranking, user experience, and conversions with on-page optimization by our expert team. Fine-tune meta tags, site structure, and content to drive targeted traffic and ensure online success.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Off-site Optimization</h3>
                                    <p className="services_p">Maximize your online presence with off-site optimization strategies, boosting visibility and authority. Utilize quality backlinks, social signals, and partnerships to expand reach, increase organic traffic, and dominate the digital landscape. Watch your business thrive beyond the website's boundaries.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Technical SEO</h3>
                                    <p className="services_p">Experience the full potential of your website with technical SEO expertise. Our specialists optimize site speed, enhance crawlability, and ensure seamless user experiences. We fine-tune technical elements like XML sitemaps and schema markup, boosting your site's search engine rankings and enhancing your online performance.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Local SEO</h3>
                                    <p className="services_p">For businesses targeting a local audience, we implement effective local SEO strategies. This includes optimizing your Google My Business profile, creating local citations, and ensuring consistent NAP (Name, Address, Phone number) information across online directories. Local SEO helps your business appear prominently in local search results, driving foot traffic and generating more leads in your local area.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Content Marketing</h3>
                                    <p className="services_p">Our SEO services also involve content marketing strategies. We create high-quality, relevant, and valuable content that not only attracts your target audience but also earns natural backlinks from other authoritative websites. Engaging content enhances your website's authority, builds trust with your audience, and improves its overall SEO performance.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">SEO Reporting and Analytics</h3>
                                    <p className="services_p">Transparency and data-driven decision-making are at the core of our services. We provide regular SEO reports that track your website's performance, keyword rankings, organic traffic growth, and other key metrics. These insights help you understand the impact of our efforts and make informed decisions to further optimize your online presence.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center mt-md-1">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium">S/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Monitoring and Optimization</h3>
                                    <p className="services_p">SEO is an ongoing process, and search engine algorithms constantly evolve. Our team continuously monitors your website's performance, keeps up-to-date with industry changes, and adapts your SEO strategy accordingly. This ensures that your website maintains its visibility and competitiveness in the ever-changing digital landscape.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}