import React from 'react'

export default function Arrow() {
      return (
            <>
                  <svg xmlns="http://www.w3.org/2000/svg" width={7} height={11} viewBox="0 0 7 11" fill="none">
                        <path d="M1.5 1.5L5.5 5.5L1.5 9.5" stroke="white" strokeWidth="1.5" />
                  </svg>
            </>
      )
}