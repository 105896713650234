import React, { useState } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function LegalBranding() {

    return (
        <>
            <Helmet>
                <title>Legal Terms of Website Use | Web Agency Terms and Conditions | aBox Agency</title>
                <meta name="title" content="Legal Terms of Website Use | Web Agency Terms and Conditions | aBox Agency" />
                <meta name="description" content="Explore aBox Agency's legal terms and conditions for website use. Understand our licensing, prohibited activities, intellectual property, confidentiality, liability, and privacy policies. Engage with confidence, knowing the governing laws and the latest updates on our services." />
                <meta name="keywords" content="aBox Agency, Legal, Terms and Conditions, Privacy Policy, Website Use, Terms of Service, Disclaimer, User Agreement, Jurisdiction, Licensing, Prohibited Activities, Confidentiality, Liability, Intellectual Property, Legal Information, Web Agency Terms, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B" />
                <link rel="canonical" href="https://www.abox.agency/legal/" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="section_detail sectin_branding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="font_medium">Branding</p>
                            <h1 className="portfolio_heading font_medium">Legal</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* Branding Date Section */}
            <div className="section_date">
                <div className="container">
                    <div className="branding_date d-flex align-items-center">
                        <span className="contact_span">Last Updated </span>
                        <span className="date_border"></span>
                        <span className="color_dark">November 1, 2023.</span>
                    </div>
                </div>
            </div>

            {/* Legal services List */}
            <div className="section_legal">
                <div className="container">
                    <div className="row seo_border legal_content">
                        <div className="col-xl-6 branding_padding pt-0">
                            <h2 className="service_subheading color_dark">Introduction</h2>
                        </div>
                        <div className="col-xl-6 branding_padding pt-0 seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">Welcome to aBox Agency's Legal Page. This document outlines the terms and conditions under which you may access and use our website and services. By using our website or engaging with our services, you agree to comply with these terms. If you do not agree with these terms, please refrain from using our services.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Definitions</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark seo_pb">aBox Agency refers to our web agency, including its employees, directors, and affiliated entities.</p>
                                <p className="seo_content mb-0 color_dark">A user refers to individuals or entities accessing or using our website and services.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Website Use</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <div className="seo_pb">
                                    <h3 className="legal_title">License</h3>
                                    <p className="seo_content mb-0 color_dark">We grant you a limited, non-exclusive, and revocable license to access and use our website for personal or business purposes.</p>
                                </div>
                                <div className="seo_pb">
                                    <h3 className="legal_title">Prohibited Activities</h3>
                                    <p className="seo_content mb-0 color_dark">You agree not to engage in any activities that may disrupt or interfere with the functionality of our website or services. Prohibited activities include, but are not limited to, hacking, unauthorized access, or any unlawful activities.</p>
                                </div>
                                <div>
                                    <h3 className="legal_title">Intellectual Property</h3>
                                    <p className="seo_content mb-0 color_dark">All content on our website is the property of aBox Agency and is protected by intellectual property laws. You agree not to reproduce, distribute, or use our content without our explicit permission.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Services</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <div className="seo_pb">
                                    <h3 className="legal_title">Engagement</h3>
                                    <p className="seo_content mb-0 color_dark">When engaging aBox Agency for services, additional terms and conditions may apply. These will be communicated to you in writing and will supplement these general terms.</p>
                                </div>
                                <div>
                                    <h3 className="legal_title">Confidentiality</h3>
                                    <p className="seo_content mb-0 color_dark">We treat all client information and project details as confidential. Please refer to our Privacy Policy for more details.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Liability</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <div className="seo_pb">
                                    <h3 className="legal_title">Disclaimer</h3>
                                    <p className="seo_content mb-0 color_dark">While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy or completeness of the content on our website. Your use of our services is at your own risk.</p>
                                </div>
                                <div>
                                    <h3 className="legal_title">Limitation of Liability</h3>
                                    <p className="seo_content mb-0 color_dark">aBox Agency shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of our website or services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Privacy</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <h3 className="legal_title">Privacy Policy</h3>
                                <p className="seo_content mb-0 color_dark">Your use of our services is also governed by our Privacy Policy. Please review this policy to understand how we collect, use, and protect your personal information.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Governing Law</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <h3 className="legal_title">Jurisdiction</h3>
                                <p className="seo_content mb-0 color_dark">These terms are governed by and construed in accordance with the laws of Indian Jurisdiction. Any disputes arising from these terms or your use of our services shall be subject to the exclusive jurisdiction of the courts in India.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Changes to Terms</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <h3 className="legal_title">Updates</h3>
                                <p className="seo_content mb-0 color_dark">We reserve the right to update or modify these terms at any time without prior notice. The latest version will be posted on our website, and it is your responsibility to review these terms periodically.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding pb-0">
                            <h2 className="service_subheading color_dark">Contact Information</h2>
                        </div>
                        <div className="col-xl-6 branding_padding pb-0">
                            <div className="content_pad">
                                <h3 className="legal_title">Questions</h3>
                                <p className="seo_content mb-0 color_dark">If you have any questions or concerns about these terms, please contact us at <a href="mailto:hi@abox.agency" target="_blank" className="font_medium color_dark branding_link">hi@abox.agency.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="branding_services">
                <div className="container">
                    <h2 className="legal_services">
                        Thank you for choosing <span>aBox Agency.</span> <br />
                        We look forward to providing you with excellent web services.
                    </h2>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}