import React from 'react'

export default function LeftArrow() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} viewBox="0 0 16 13" fill="none">
                <path d="M16 6.33252H2M2 6.33252L7.46341 0.83252M2 6.33252L7.46341 11.8325" stroke="black" strokeWidth="1.5" />
            </svg>
        </>
    )
}