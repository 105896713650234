import React from 'react'

export default function Loader() {
    return (
        <>
            <div className="Loader_center">
                <span className="loader"></span>
            </div>
        </>
    )
}