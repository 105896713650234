import React from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function Career() {

    return (
        <>
            <Helmet>
                <title>Start Your Journey | Join Our Team Today! aBox Agency Contact</title>
                <meta name="title" content="Start Your Journey | Join Our Team Today! aBox Agency Contact" />
                <meta name="description" content=" Begin an exciting journey by joining our team at aBox Agency. Contact us today to explore career opportunities and become a part of our creative and innovative workforce." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Job, Join Our Team, Career, Opportunities, Creative Workforce, Web Agency Jobs, Job Openings, Employment at Box, Innovative Careers, Creative Professionals, Web Industry Jobs, Team Collaboration, Career Growth, Creative Opportunities, Web Agency Employment, Be Part of Our Team, Job Application, Web Industry Careers, Join aBox Agency, Job Vacancies, Creative Team Members, Work with Us, Web Professionals, Explore Opportunities, Career Development, Innovative Work, Environment, Contact for Jobs" />
                <link rel="canonical" href="https://www.abox.agency/career/" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail career_title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <p className="font_medium">Careers</p>
                                <h1 className="portfolio_heading">Join our award-winning <br className="d-lg-block d-none" />
                                    team at <br className="d-md-none d-block" /> A Box.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="container-fluid p-0">
                <div>
                    <img src="/assets/image/career.webp" alt="career" className="w-100" loading="lazy" />
                </div>
            </div>

            {/* job section */}
            <div className="section_detail job_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="career_h2">We’re always looking for talented people that move
                                us bolder, dream bigger and perform better.</h2>
                        </div>
                        <div className="col-lg-10 margin_70">
                            <p className="image_p color_light">At A Box you will get to work with some of the world's leading brands, on innovative creative and technology initiatives - in an environment that champions creativity and personal development.</p>
                        </div>
                        <div className="col-lg-3">
                            <button type="submit" className="btn submit_btn">Apply for job
                                <svg className="ms-2 submit_arrow" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                    <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                </svg>
                                <svg className="ms-2 submit_hover" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                    <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* faq section */}
            <div className="section_detail job_section bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="accordion" id="accordionFlus">
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button pt-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <span className="faq_span">01</span>
                                            UI/UX, Visual Designer
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionFlus"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq_content margin_70">
                                                We’re looking for an experienced full-time digital designer to join our team and help shape the future of companies around the world. You will work closely with our Creative Director to design products, websites and accompanying graphics.
                                            </p>
                                            <div className="d-flex flex-wrap">
                                                <p className="feature_p color_dark allborder_dark">Adobe XD</p>
                                                <p className="feature_p color_dark allborder_dark">Wireframes & User Flow</p>
                                                <p className="feature_p color_dark allborder_dark">Prototypes</p>
                                                <p className="feature_p color_dark allborder_dark">Figma</p>
                                                <p className="feature_p color_dark allborder_dark">UX Research</p>
                                                <p className="feature_p color_dark allborder_dark">Personas</p>
                                                <p className="feature_p color_dark allborder_dark">Design System</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                        >
                                            <span className="faq_span">02</span>
                                            Front-end Developer
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionFlus"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq_content margin_70">
                                                We’re looking for an experienced full-time frontend developer to join our team and help shape the future of companies around the world. You will work closely with our Creative Director and Senior Developer to build pixel perfect websites that are incredibly fast and responsive.
                                            </p>
                                            <div className="d-flex flex-wrap">
                                                <p className="feature_p color_dark allborder_dark">HTML CSS JavaScript</p>
                                                <p className="feature_p color_dark allborder_dark">NuxtJS</p>
                                                <p className="feature_p color_dark allborder_dark">NodeJS</p>
                                                <p className="feature_p color_dark allborder_dark">VueJS</p>
                                                <p className="feature_p color_dark allborder_dark">Responsive Website</p>
                                                <p className="feature_p color_dark allborder_dark">Webpack, SASS, ES6 JS</p>
                                                <p className="feature_p color_dark allborder_dark">Pixel Perfect</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="true"
                                            aria-controls="collapseThree"
                                        >
                                            <span className="faq_span">03</span>
                                            Project Manager
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionFlus"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq_content margin_70">
                                                We are looking for a qualified Digital project manager to join our team. You will be responsible for the day-to-day management and smooth operation of various digital projects, including site launches, online tools, web applications and advertising campaigns.
                                            </p>
                                            <div className="d-flex flex-wrap">
                                                <p className="feature_p color_dark allborder_dark">Project Scheduling</p>
                                                <p className="feature_p color_dark allborder_dark">Project Lifecycle</p>
                                                <p className="feature_p color_dark allborder_dark">Agile Software (Jira)</p>
                                                <p className="feature_p color_dark allborder_dark">SCRUM</p>
                                                <p className="feature_p color_dark allborder_dark">Kanban</p>
                                                <p className="feature_p color_dark allborder_dark">Technical Writing</p>
                                                <p className="feature_p color_dark allborder_dark">Project Forecasting</p>
                                                <p className="feature_p color_dark allborder_dark">Performance Tracking</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item m-0">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="true"
                                            aria-controls="collapseFour"
                                        >
                                            <span className="faq_span">04</span>
                                            Digital Marketing Manager
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionFlus"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq_content margin_70">
                                                You’d be in charge of the strategy, management and communication aspects of the things we create. You’d spend your time leading brand, web and product design projects, thinking-up unique and effective ways for our work to drive real business outcomes (like, engagement or lead generation), and generally ensuring everything runs smoothly and arrives on-time.
                                            </p>
                                            <div className="d-flex flex-wrap">
                                                <p className="feature_p color_dark allborder_dark">Experience Strategy</p>
                                                <p className="feature_p color_dark allborder_dark">Content Strategy</p>
                                                <p className="feature_p color_dark allborder_dark">Copywriting</p>
                                                <p className="feature_p color_dark allborder_dark">Brand Strategy</p>
                                                <p className="feature_p color_dark allborder_dark">Communication & PR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}