import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioSinglas() {

    return (
        <>
            <Helmet>
                <title>Singlas Sweets Website Development | Custom Shopify & UIUX Design | aBox Agency</title>
                <meta name="title" content="Singlas Sweets Website Development | Custom Shopify & UIUX Design | aBox Agency" />
                <meta name="description" content="Explore how Singlas Sweets transformed their online presence with top-notch website development, UI/UX design, and branding. Discover a site that shines just as brightly as their sweets." />
                <meta name="keywords" content="website development, UI/UX design, Shopify development, branding, Indian sweets, digital makeover, Singlas Sweets, aBox Agency, Niqox, sweets industry, custom website design, eCommerce solutions, online branding, digital transformation, user experience, website graphics, site optimization, responsive design, SEO services, sweets market, web development, digital presence, brand enhancement, Shopify integration, online store design, market positioning, web design services, user interface, digital marketing, site performance, branding strategy" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/singlas-sweets/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_singlas_sweets">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name singlas_text2_bg omsweets_text">Singla’s Sweets</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/Preview.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name singlas_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title singlas_sweets_text mb-0">
                                        Sweetening Up <br />
                                        <span className="singlas_sweets_title">
                                            The Digital Taste
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content singlas_sweets_text mb-3">Hey there! We’ve just given a fantastic Singla’s Sweets a major digital makeover. Imagine combining top-notch UI/UX design with Shopify and a sprinkle of branding brilliance—that’s exactly what we did! The result?</p>
                                    <p className="services_content singlas_sweets_text">A website that’s as charming online as their sweets are in real life. Curious about how we made it all happen?</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://singlasweets.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="singlas_sweets_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list singlas_sweets_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="singlas_sweets_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list singlas_sweets_list">
                                                <li>Confectionery sweets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_murari_logo">
                                <img src="/assets/image/portfolios/singlas/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/singlas/singlas1.webp" className="w-100" alt="Singlas Sweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title singlas_sweets_title">Website Makeover for Digital Impact</h2>
                                    <p className="services_pera singlas_sweets_text font_regular mb-0">
                                        So, we took Singla Sweets brand’s online game to the next level. With custom UI/UX design and a shiny new Shopify setup, we solved their digital issues and made their site look amazing.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/singlas/singlas2.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/singlas/singlas3.webp"
                                            alt="Singlas Sweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/singlas/singlas4.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/singlas/singlas5.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/singlas/singlas6.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/singlas/singlas7.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="aumex pb-0 sweet_delect singlas_banner_bg mt_20">
                                    <div className="gaps gap_20">
                                        <div>
                                            <img src="/assets/image/portfolios/singlas/singlas8.webp"
                                                alt="Singlas Sweets" className="img-fluid w-100" />
                                        </div>
                                        <div>
                                            <img src="/assets/image/portfolios/singlas/singlas9.webp"
                                                alt="Singlas Sweets" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title singlas_sweets_title">Enhanced Visibility and Market Position</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera singlas_sweets_text mb-0">
                                        The revamped Singla’s Sweets website brought loads of excitement and interaction. Our playful design and branding efforts made sure the brand stood out online and engaged customers in a way that’s as sweet as their products. Check it out at <a href="https://singlasweets.com/" target="_blank" className="portfolio_a omsweets_text">www.singlasweets.com.</a></p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/singlas/singlas10.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/singlas/singlas11.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/singlas/singlas12.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/singlas/singlas13.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/singlas/singlas14.webp"
                                        alt="Singlas Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title singlas_sweets_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/singlas/singlas15.webp" className="w-100" alt="Singlas Sweets color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/singlas/singlas16.webp" alt="singlas Sweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="singlas_sweets_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s singlas_sweets_title">AuroLab</h3>
                                    <p className="font_medium services_pera singlas_sweets_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg savani_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/savani-jewellery" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}