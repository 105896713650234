import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelKayura() {

    return (
        <>
            <Helmet>
                <title>Kayura Skincare | 3D Product Render & Modelling | aBox Agency</title>
                <meta name="title" content="Kayura Skincare | 3D Product Render & Modelling | aBox Agency" />
                <meta name="description" content="Abox Agency created realistic 3D renders and product visualizations for Kayura’s skincare packaging. We focused on detailed textures and lifelike models for round and oval bottles, enhancing the brand’s premium skincare products and improving their online presentation." />
                <meta name="keywords" content="Abox Agency, 3D rendering, product visualization, skincare packaging, cosmetic packaging, 3D product modeling, realistic textures, product presentation, premium skincare, skincare product design, 3D modeling for cosmetics, packaging design, beauty product renders, 3D texture detailing, skincare bottle design, product renders for skincare, cosmetic 3D visualization, product presentation for skincare, high-end skincare visuals, 3D product renders, skincare brand imagery, professional product visualization, 3D rendering for beauty brands, cosmetic packaging renders, realistic 3D modeling, 3D design for skincare, packaging presentation, beauty product 3D renders, detailed product textures, skincare eCommerce visuals, 3D cosmetic packaging design, skincare branding, cosmetic product visualization, luxury skincare product modeling, product rendering for cosmetics, 3D packaging design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/kayura-effect/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                <h1 className="services_title ras_title mb-0"><span>Kayura</span> <br /> <span className="text_black">Bringing Skincare <br /> to Life</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/kayura/kayura1.jpg"
                        alt="Kayura" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Kayura is a skincare brand that focuses on creating high-quality, natural skincare creams. They wanted to enhance the visual presentation of their products to better showcase their packaging and textures. We were tasked with creating realistic 3D renders and models for their round and oval bottles, as well as the product packaging. The goal was to bring out the elegance and premium quality of their skincare products through detailed textures and lifelike presentation.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>3D Rendering</li>
                                            <li>3D Modeling</li>
                                            <li>Product Visualization</li>
                                            <li>Packaging Design</li>
                                            <li>Texture Detailing</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Skincare & Cosmetics</li>
                                            <li>E-Commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura2.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura3.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura4.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura5.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura6.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura7.jpg" className="w-100" alt="kayura" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/kayura/kayura8.jpg" className="w-100" alt="Kayura" />
                        </div>
                        <div className="content_space">
                            <p className="services_content text_black">Our approach involved designing detailed 3D models of Kayura’s round and oval bottles, along with their packaging. Using advanced 3D rendering techniques, we meticulously worked on realistic texturing and material details to capture the essence of the product’s premium feel. Our focus was to showcase the elegant packaging and create visuals that truly represent the brand’s high-end skincare solutions. The outcome was a set of realistic 3D renders that not only elevated the brand’s online presence but also allowed Kayura to showcase their products with the precision and quality they deserve, enhancing customer engagement and appeal in the competitive skincare market.</p>
                        </div>
                        <div className="row mb-5 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura9.jpg" className="w-100" alt="Kayura" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/kayura/kayura10.jpg" className="w-100" alt="Kayura" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}