import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioYoGathiya() {

    return (
        <>
            <Helmet>
                <title>Yo Gathiya’s Digital Presence | Success Stories: Namkeen & Savory Products | aBox Agency</title>
                <meta name="title" content="Yo Gathiya’s Digital Presence | Success Stories: Namkeen & Savory Products | aBox Agency" />
                <meta name="description" content="Discover how aBox and Yo Gathiya have brought Gujarat's traditional flavors online. Our collaboration resulted in a dynamic online platform showcasing Yo Gathiya’s high-quality Namkeen and Savory products, embodying innovation and authenticity." />
                <meta name="keywords" content="Yo Gathiya, aBox, Namkeen, Savory, E-commerce, Web Agency, Gujarat, Web Development, Branding, Logo Design, Social Media, Client Success Stories, Collaborations, Valued Partnerships, Namkeen Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/yo-gathiya/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_yogathiya">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Yo Gathiya</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724145615.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod omsweets_text">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Yo Gathiya <br />
                                        <span className="yogathiya_title">
                                            Bringing Gujarat's Flavors Online
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Yo Gathiya, a beloved local brand from Gujarat, offers exceptional Namkeen and Savory delights but lacks an online presence. They turned to us, aBox, to create a vibrant digital identity. We suggested the catchy name "Yo Gathiya" inspired by Bhagvnagri Gathiya, and embarked on a comprehensive branding journey. From logo design to social media presence, we transformed their digital footprint. Today, Yo Gathiya’s online presence showcases their delicious products to a wider audience, thanks to our collaborative effort and dedication.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://yogathiya.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>3D render</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Namkeen and Savory Products</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_yogathiya_logo">
                                <img src="/assets/image/portfolios/yogathiya/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/yogathiya/yogathiya1.webp" className="w-100" alt="Yo Gathiya" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yogathiya_title">Crafting Yo Gathiya’s Digital Identity</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        At aBox, our team worked tirelessly to create a compelling online presence for Yo Gathiya. We developed a catchy brand name, designed an eye-catching logo, and established a robust social media presence. The website we built offers a seamless and engaging shopping experience, perfectly capturing the essence of their flavorful offerings.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya2.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya3.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya4.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya5.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya6.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya7.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect yogathiya_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/yogathiya/yogathiya8.webp"
                                            alt="Yo Gathiya" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/yogathiya/yogathiya9.webp"
                                            alt="Yo Gathiya" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title yogathiya_title">Blending Tradition with a Modern Twist</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Yo Gathiya, rooted in Gujarat's rich culinary tradition, now shines online with a modern twist. Visit <a href="https://yogathiya.com/" target="_blank" className="official_link omsweets_text">www.yogathiya.com</a> to savor the perfect blend of traditional flavors and contemporary convenience, representing quality and authenticity in the Namkeen and Savory industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya10.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya11.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya12.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya6.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya13.webp"
                                        alt="Yo Gathiya" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title yogathiya_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/yogathiya/yogathiya14.webp" className="w-100" alt="Yo Gathiya color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya15.webp" alt="Yo Gathiya font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">From branding to web development, aBox has been phenomenal. The new website and social media presence capture the essence of our Namkeen and savory products perfectly. Their creative approach has significantly boosted our online visibility.</p>
                                    <h3 className="title_color padd_s yogathiya_title">Sagar CEO Founder</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Yo Gathiya</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg codifi_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/codifi" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}