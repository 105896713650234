import React from 'react'
import { CopyLinkColors } from '../../mappings'

export default function SiteLink() {
    return (
        <>
            <svg className="ms-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path style={{ fill: CopyLinkColors[window.location.pathname.toLowerCase()] }} d="M19.0712 14.2416C18.7812 14.5316 18.3212 14.5316 18.0413 14.2416C17.7513 13.9516 17.7513 13.4916 18.0413 13.2116C20.0413 11.2116 20.0413 7.96156 18.0413 5.97156C16.0413 3.98156 12.7912 3.97156 10.8012 5.97156C8.81125 7.97156 8.80125 11.2216 10.8012 13.2116C11.0912 13.5016 11.0912 13.9616 10.8012 14.2416C10.5112 14.5316 10.0512 14.5316 9.77125 14.2416C7.20125 11.6716 7.20125 7.49156 9.77125 4.93156C12.3412 2.37156 16.5212 2.36156 19.0812 4.93156C21.6412 7.50156 21.6412 11.6716 19.0712 14.2416Z" fill="#9C8EFF" />
                <path style={{ fill: CopyLinkColors[window.location.pathname.toLowerCase()] }} d="M4.92875 9.76047C5.21875 9.47047 5.67875 9.47047 5.95875 9.76047C6.24875 10.0505 6.24875 10.5105 5.95875 10.7905C3.95875 12.7905 3.95875 16.0405 5.95875 18.0305C7.95875 20.0205 11.2088 20.0305 13.1988 18.0305C15.1888 16.0305 15.1988 12.7805 13.1988 10.7905C12.9088 10.5005 12.9088 10.0405 13.1988 9.76047C13.4888 9.47047 13.9488 9.47047 14.2288 9.76047C16.7988 12.3305 16.7988 16.5105 14.2288 19.0705C11.6588 21.6305 7.47875 21.6405 4.91875 19.0705C2.35875 16.5005 2.35875 12.3305 4.92875 9.76047Z" fill="#9C8EFF" />
            </svg>
        </>
    )
}