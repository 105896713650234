import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { Link, useSearchParams } from "react-router-dom";
import DataNotFound from "../component/DataNotFound";
import Loader from "../component/Loader";
import portfolioData from "../data/portfolios"

export default function Portfolio() {

    const [searchParams, setSearchParams] = useSearchParams()

    const [category, setCategory] = useState(searchParams.get('tab') || 'all')
    const [filterdata, setFilterdata] = useState([])

    useEffect(() => {
        if (portfolioData) {
            if (category === 'all') {
                setFilterdata(portfolioData)
            } else {
                setFilterdata(
                    portfolioData.filter(item =>
                        item.categories && item.categories.includes(category)
                    )
                );
            }
        }
    }, [category, portfolioData]);

    // const getTooptip = (id) => {
    //     let toolTip = document.getElementById(id);
    //     window.addEventListener('mousemove', function (e) {
    //         let x = e.clientX,
    //             y = e.clientY;
    //         toolTip.style.top = (y + 15) + 'px';
    //         toolTip.style.left = (x + 15) + 'px';
    //     });
    // }

    function changeColor(...args) {
        document.getElementById("portfolio").style.backgroundColor = args[0];
        const elements = document.querySelectorAll(`.color_light.change-color`)
        const tabsElement = document.querySelectorAll(`.tabs`)
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.color = args[1]
        }
        for (let i = 0; i < tabsElement.length; i++) {
            tabsElement[i].classList.add('#EFEBE750')
        }
    }

    return (
        <>
            <Helmet>
                <title>Portfolios | Transforming Visions into Reality | Delve into Our Work | aBox Agency</title>
                <meta name="title" content="Portfolios | Transforming Visions into Reality | Delve into Our Work | aBox Agency" />
                <meta name="description" content="Explore our portfolio showcasing how aBox Agency turns visions into reality. Discover our transformative projects that highlight creativity and innovation in web design and development." />
                <meta name="keywords" content="Box, Shopify, E-coomerce, Web Agency, Portfolio, Web Development, Shopify store, WorkFolio, Showcase, Web Design Projects, Creative Development, Client Projects, Website Showcase, Digital Transformation, Design Innovation, Visual Creativity, Web Development Work, Online Solutions, Client Success Stories, Transformative Designs, Creative Showcase, Innovation in Web Design, Digital Portfolio, Realizing Visions, Client Transformations, Web Project Highlights, Innovative Web Solutions, aBox Agency Portfolio, Web Design Excellence, Client-Centric Solutions, Success in, Web Development" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/" />
            </Helmet>

            <Header />

            {/* project section */}
            <div id="portfolio" className="section_detail default_bg portfolio_section">
                <div className="container">
                    <div className="portfolio_title">
                        <div className="row">
                            <div className="col-xl-9">
                                <p className="mb-1 font_medium color_light change-color">Portfolios</p>
                                <h1 className="portfolio_heading color_light change-color">Making vibrant brands and unforgettable digital presence.</h1>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-pills portfolio" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'all' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'all' })
                                    setCategory('all')
                                }}>
                                All
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'e-commerce' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'e-commerce' })
                                    setCategory('e-commerce')
                                }}>
                                E-commerce
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'ui-design' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'ui-design' })
                                    setCategory('ui-design')
                                }}>
                                UI Design
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'packaging-design' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'packaging-design' })
                                    setCategory('packaging-design')
                                }}>
                                Packaging Design
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'branding' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'branding' })
                                    setCategory('branding')
                                }}>
                                Branding
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === '3d' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': '3d' })
                                    setCategory('3d')
                                }}>
                                3D
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'webflow' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'webflow' })
                                    setCategory('webflow')
                                }}>
                                Webflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={category === 'custom-development' ? "nav-link tabs active" : "nav-link tabs"}
                                onClick={() => {
                                    setSearchParams({ 'tab': 'custom-development' })
                                    setCategory('custom-development')
                                }}>
                                Custom Development
                            </button>
                        </li>
                    </ul>

                    <div className="div-block-40 flex-column">
                        <div className="row">
                            {filterdata ? filterdata.length ?
                                filterdata?.map((i, index) =>
                                    <div className={`col-lg-6 ${(index + 1) % 2 == 0 ? "space_left" : "space_right"}`} key={index}>
                                        <div className={`projects me-0 ${(index + 1) % 2 == 0 ? "margin-top" : ""}`}>
                                            <div className="anand-sweets">
                                                <Link
                                                    to={i.href}
                                                    className="project-link"
                                                    onMouseOver={() => changeColor(i.onMouseOverColor.bg, i.onMouseOverColor.text)}
                                                    onMouseOut={() => changeColor('#222', '#efebe7')}
                                                >
                                                    <img src={i.imgSrc} loading="lazy" alt={i.imgAlt} className="w-100" />
                                                    <div className="div-block-42">
                                                        <p className="project-name change-color color_light">{i.projectName}</p>
                                                        <div className="line dark_line" />
                                                    </div>
                                                    <h2 className="project-info change-color color_light">{i.projectInfoOne} <br className="d-lg-block d-none" /> {i.projectInfoTwo}</h2>
                                                    <p className="paragraph-13 change-color color_light">{i.descriptionOne} <br className="d-lg-block d-none" /> {i.descriptionTwo} </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <DataNotFound />
                                : <Loader />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}