import React from "react";

export default function LetsTalk() {

    return (
        <>
            <div className="section_detail letstalk_section">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-10 col-12">
                            <h2 className="letstalk_h2">Inspired by an incredible idea?
                                <br className="d-xl-none d-lg-block" />Let’s Bring it to the reality.</h2>
                            {/* <a href="/contact" className="lets_talk">Work with us
                                <img src="/assets/image/arrow.svg" loading="lazy" alt="arrow" className="ms-2 talk_arrow" />
                            </a> */}
                            <div className="position-relative zindex2">
                                <a href={'/contact'} className="big_btn white_hover mx-auto">Work with us</a>
                                <div className="border_multi">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={1748} height={670} viewBox="0 0 1748 670" fill="none">
                                        <rect x="230.5" y="230.5" width={1287} height={209} rx="104.5" stroke="#fff" strokeOpacity="0.3" />
                                        <rect x="130.5" y="130.5" width={1487} height={409} rx="199.5" stroke="#fff" strokeOpacity="0.2" />
                                        <rect x="0.5" y="0.5" width={1747} height={669} rx="334.5" stroke="#fff" strokeOpacity="0.1" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}