import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelMaadico() {

      return (
            <>
                  <Helmet>
                        <title>3D Rendering for Maadico Wellness | aBox Agency</title>
                        <meta name="title" content="3D Rendering for Maadico Wellness | aBox Agency" />
                        <meta name="description" content="Abox Agency provided 3D rendering and modelling services for Maadico Wellness, creating lifelike visuals for their herbal products’ packaging. We enhanced the appeal of their pouches and bottle for better branding and marketing." />
                        <meta name="keywords" content="3D rendering, product modeling, packaging design, wellness products, herbal products, gut health, hormonal balance, diabetes care, 3D product visualization, realistic 3D models, eCommerce visuals, product presentation, lifelike renders, 3D modeling, packaging mockups, natural wellness, product texture design, health-focused branding, product packaging, 3D product rendering, brand elevation, realistic product imagery, premium product design, herbal wellness, digital marketing, 3D visualization for products, product textures, 3D product packaging, Maadico Wellness, professional product renders, health and wellness branding, 3D design for eCommerce, realistic packaging design, 3D textures, health and nutrition visuals." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/maadico/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Maadico Wellness</span> <br /> <span className="text_black">3D Rendering & Packaging <br className="d-sm-block d-none"/> for Herbal Products</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/maadico/maadico1.jpg"
                                    alt="Maadico" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Maadico Wellness offers herbal solutions for gut health, hormonal balance, and diabetes care. We partnered with Maadico to create realistic 3D renderings and models for their product packaging, enhancing the appeal of their pouches and bottles. The goal was to present their products professionally, showcasing their premium quality while aligning with their natural, health-focused brand.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Product Visualization</li>
                                                                  <li>Texturing and Material Design</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Wellness & Health</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico2.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico3.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico4.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico5.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We focused on 3D modeling and rendering to capture the essence of Maadico’s products, paying close attention to details, textures, and materials. The resulting visuals helped elevate their online presence, providing them with lifelike product imagery for eCommerce and marketing. The realistic packaging renders boosted their brand appeal, setting them apart in the competitive wellness industry.</p>
                                    </div>
                                    <div className="row gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico6.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico7.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico8.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico9.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico10.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maadico/maadico11.jpg" className="w-100" alt="Maadico" />
                                                </div>
                                          </div>
                                    </div>                                    
                                    <div className="mt_30 mb-4">
                                          <img src="/assets/image/label/maadico/maadico12.jpg" className="w-100" alt="Maadico" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}