import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioHealthoxide() {

    return (
        <>
            <Helmet>
                <title>HealthOxide Portfolio | Sports Supplements & Diet Nutrition Showcase | aBox Agency</title>
                <meta name="title" content="HealthOxide Portfolio | Sports Supplements & Diet Nutrition Showcase | aBox Agency" />
                <meta name="description" content="Explore our portfolio for HealthOxide, a leading brand in sports supplements and diet nutrition. Witness the excellence in e-commerce, web development, and website design brought to life by aBox Agency. Discover the world of health and fitness through our web solutions." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, UIUX, HealthOxide, Sports Supplements, Diet Nutrition, E-commerce, Web Development, Website Design, Portfolio Showcase, aBox Agency, Health and Fitness, Online Store, Web Solutions, Shopify Integration, Ecommerce Solutions, Web Development Services, Website Development, Web Design Showcase, Branding Solutions, Web Agency Portfolio, UI/UX Design, Web Development Expertise, Professional Web Design, Ecommerce Success, Website Branding, Shopify Web Solutions, Ecommerce Development, UI/UX, Web Agency Services, and Web Design Excellence" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/healthoxide/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_healthoxide">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">healthoxide</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1697525406.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2022</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Fueling Your Fitness <br /> Journey with Pure Wellness
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">HealthOxide, a well-known supplier of nutritional supplements, used to conduct business mostly through third-party companies and on paper. They wanted to create their own e-commerce website where they could display their vast inventory of more than 100 products in an effort to streamline their services. Together, they and Abox turned their concept into a reality. HealthOxide is now flourishing thanks to the development of a one-stop shop website, thorough branding, and a strong online presence—especially following the installation of a Shopify website.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.healthoxide.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Health and Wellness Supplements</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_oxide">
                                <img src="/assets/image/portfolios/healthoxide/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/healthoxide/healthoxide2.webp" className="w-100" alt="healthoxide" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting a Digital Oasis for Health and Vitality</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Healthoxide entrusted us with the task of designing a website that embodies the essence of a healthy lifestyle. Our team delved into creating a visually appealing platform that mirrors the freshness and purity of Healthoxide's products. Through a harmonious blend of design elements, we've crafted an online space that invites you to explore the world of health, weight management, and bodybuilding supplements.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/healthoxide/healthoxide3.webp"
                                            alt="healthoxide" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/healthoxide/healthoxide4.webp"
                                            alt="healthoxide" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/healthoxide/healthoxide5.webp"
                                            alt="healthoxide" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/healthoxide/healthoxide6.webp"
                                            alt="healthoxide" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/healthoxide/healthoxide7.webp"
                                    alt="healthoxide" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_oxide">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide8.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide9.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide10.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Building a Seamless Path to Wellness</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Our tech-savvy experts dedicated themselves to constructing a website that aligns perfectly with Healthoxide's mission. Every feature has been meticulously customized to meet their unique requirements, ensuring a seamless online shopping experience. Healthoxide takes you on a transformative journey towards a healthier and fitter you. Our website, <a href="https://www.healthoxide.com/" target="_blank" className="text_light services_link">https://www.healthoxide.com/</a>, serves as your gateway to a world of wellness.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide11.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide12.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide13.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide14.webp"
                                        alt="healthoxide" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/healthoxide/healthoxide15.webp"
                                    alt="healthoxide" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/healthoxide/healthoxide16.webp" className="w-100" alt="healthoxide color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/healthoxide/healthoxide17.webp" alt="healthoxide font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Abox Agency has been fantastic for us. They made our website look really good, which is super important for Healthoxide. They also did an awesome job designing labels for our products. We want to say a big thank you to them for making us look great online and in stores.</p>
                                    <h3 className="title_color padd_s text_shiska">Mr. Jignesh Kotadiya</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Heathoxide</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg maababy_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/maa-and-baby" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}