import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioUbar() {

    return (
        <>
            <Helmet>
                <title>Ubars Portfolio | Healthy Protein Bars & Snacks | aBox Agency</title>
                <meta name="title" content="Ubars Portfolio | Healthy Protein Bars & Snacks | aBox Agency" />
                <meta name="description" content="Explore our portfolio showcasing the success of Ubars, a provider of healthy protein bars and snacks. See how aBox Agency helped boost their online presence and promote high-quality, nutritious snacks." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, UIUX, Ubars, Healthy Snacks, Protein Bars, Nutritious Snacks, Portfolio Showcase, aBox Agency, Online Presence, Success Story, Healthy Eating, Quality Snack Products, Collaborations, Valued Partnerships, Nutritional Snacks, Healthy Eating, Protein-Rich Bars, Natural Snacks, Online Portfolio, Creative Solutions, Digital Marketing, Responsive Design, SEO Optimization, Healthy Lifestyle" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ubar/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_maroon">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text_light">Ubar</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1697525465.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text_light">Published in 2020</p>
                                    <h1 className="services_title ubar_text mb-0">
                                        Fueling Your Health <br /> with Protein-Packed <br /> Delights
                                        {/* <span className="ubar_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">U-Bar is a wellness brand that offers delicious, healthy, and protein-packed snacks. They came up with the concept of offering simple health supplements to the community during the initial lockdown. They needed an online presence, even though they had a gym and knowledge of wellness. Recognizing their vision, aBox comprehensively understood their idea and provided them with a website and branding to boost their business online.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://ubars.in/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>3D Render</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>For Startup</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Health Food Products</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_ubar">
                                <img src="/assets/image/portfolios/ubar/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/ubar/ubar2.webp" className="w-100" alt="ubar" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Building the Perfect Foundation for a Healthy Brand</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech-savvy experts have poured their expertise into ensuring that Ubars' vision comes to life with precision. We've customized every feature to cater to their unique needs, resulting in a seamless online shopping experience. Our user-friendly interface showcases a diverse range of protein bars and snacks that are both wholesome and scrumptious.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/ubar/ubar3.webp"
                                            alt="ubar" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/ubar/ubar4.webp"
                                            alt="ubar" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/ubar/ubar5.webp"
                                            alt="ubar" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/ubar/ubar6.webp"
                                            alt="ubar" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/ubar/ubar7.webp"
                                    alt="ubar" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_pink">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/ubar/ubar8.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ubar/ubar9.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ubar/ubar10.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Enhancing Health with Flair</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Ubars takes you on a delightful journey through the world of protein bars and snacks, where taste meets health with finesse. Join us at <a href="https://ubars.in/" target="_blank" className="text_light services_link">www.ubars.in</a> for a delicious adventure where every bite is a step towards a healthier, happier you.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/ubar/ubar11.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ubar/ubar12.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ubar/ubar13.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ubar/ubar14.webp"
                                        alt="ubar" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/ubar/ubar15.webp"
                                    alt="ubar" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/ubar/ubar16.webp" className="w-100" alt="ubar color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/ubar/ubar17.webp" alt="ubar font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Ubars has been blown away by Abox Agency's exceptional services! They created a website that beautifully showcases our healthy protein bars. The team's dedication to design, web development, and package design is evident in every detail. Our site not only tantalizes taste buds but also promotes a healthier lifestyle. Abox Agency exceeded our expectations. Highly recommended!</p>
                                    <h3 className="title_color padd_s text_shiska">Mr. Prashant Binnay</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Ubars</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg freshleaf_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/freshleaf" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}