import React from "react";
import { Helmet } from "react-helmet";

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Page Not Found | aBox Agency</title>
                <meta name="title" content="Page Not Found | aBox Agency" />
            </Helmet>

            <div className="bg-white">
                <div className="page_header text-center">
                    <a href="/">
                        <svg width="80" height="37" viewBox="0 0 80 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M80 37H0V0H73.8457V6.16667H80V37ZM61.5383 24.6667H67.6926V30.8333H73.8469V24.6667H67.6926V18.5H61.5383V6.16667H55.384V18.5H61.5383V24.6667H55.384V30.8333H61.5383V24.6667ZM30.7691 6.16667V30.8333H49.2309V12.3333H43.0766V6.16667H30.7691ZM6.15429 6.16667V30.8333H24.616V18.5H18.4617V12.3333H12.3074V6.16667H6.15429ZM73.8457 12.3333H67.6926V18.5H73.8469L73.8457 12.3333ZM43.0766 24.6667H36.9234V12.3333H43.0766V24.6667ZM18.4617 24.6667H12.3074V18.5H18.4617V24.6667Z" fill="#222222" />
                        </svg>
                    </a>
                </div>
                <div className="section_not_found d-flex flex-column justify-content-center">
                    <div className="text-center padd_bottom">
                        <h1 className="error_title font_medium">ERROR 404</h1>
                        <img src="/assets/image/not_found.svg" className="error_img" alt="Not Found" loading="lazy" />
                    </div>
                    <div className="text-center">
                        <h2 className="error_pera font_regular">Page not found, Something went wrong in the universe!</h2>
                        <a href="/" className="back_btn font_medium">Back to homepage
                            <img src="/assets/image/back_arrow.svg" alt="Back Arrow" />
                        </a>
                    </div>
                </div>
                <div className="section-footer">
                    <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
                        <p className="advanced_p text_size text-lg-start text-center font_medium mb-lg-0 mb-3">
                            <span className="footer_span">Advanced </span>
                            Business-oriented Operations and expertise CO.LTC
                        </p>
                        <div className="d-flex align-items-center justify-content-lg-end justify-content-center">
                            <div className="contact_no"><p>+91 7046216489</p></div>
                            <p className="ms-2 contact_pera text_size">For Quick Talk</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}