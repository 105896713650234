import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ThankYou() {
    return (
        <>
            <Helmet>
                <title>Thank You for Reaching Out | Get in Touch | aBox Agency</title>
                <meta name="title" content="Thank You for Reaching Out | Get in Touch | aBox Agency" />
                <meta name="description" content="Thank you for getting in touch with ABox Agency! We've received your inquiry and our team will be in contact shortly. In the meantime, feel free to explore more about our services and success stories. We look forward to the opportunity of working together to elevate your digital presence." />
                <meta name="keywords" content="aBox agency, web agency, thank you, contact, Shopify, E-Commerce, UI/UX, web design, development, onsite SEO, A/B testing, startup, projects, about us, careers, blogs, services, 3D render, shopify, wordpress" />
                <link rel="canonical" href="https://www.abox.agency/thank-you/" />
            </Helmet>

            <Header />

            <section className='thankyou_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-5'>
                            <div>
                                <h1 className='thankyou_title'>Thank you for <br />
                                    Contacting us!</h1>
                                <p className='thankyou_p'>A member of our team will reach out to you shortly.</p>
                            </div>
                        </div>
                        <div className='col-xl-7 mt-xl-0 mt-5'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <a href='https://d1cihhbb125h48.cloudfront.net/aBox_Deck.pdf' target='_blank'>
                                        <div>
                                            <img src="/assets/image/thankyou/thankyou1.webp" alt="thankyou" className="w-100 thankyou_a" loading="lazy" />
                                        </div>
                                        <div className='thankyou_link'>
                                            <span className='thankyou_span'>Brand deck</span>
                                            <h2 className='thankyou_h2'>Explore our ideas and services</h2>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-lg-5 col-md-6 pe-lg-2 pe-md-0'>
                                    <a href="https://dribbble.com/aboxagency" target='_blank'>
                                        <div className='thankyou_a thankyou_pink'>
                                            <img src="/assets/image/thankyou/thankyou2.svg" alt="thankyou" loading="lazy" />
                                        </div>
                                        <div className='thankyou_link'>
                                            <span className='thankyou_span'>Dribble</span>
                                            <h2 className='thankyou_h2'>Portfolios</h2>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-lg-5 col-md-6 mb-md-0 mb-2'>
                                    <a href="https://www.behance.net/aboxagency" target='_blank'>
                                        <div className='thankyou_a thankyou_pink thankyou_blue'>
                                            <img src="/assets/image/thankyou/thankyou3.svg" alt="thankyou" loading="lazy" />
                                        </div>
                                        <div className='thankyou_link mb-0'>
                                            <span className='thankyou_span'>Behance</span>
                                            <h2 className='thankyou_h2'>Workfolios</h2>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-lg-7'>
                                    <Link to={'/blogs'}>
                                        <div>
                                            <img src="/assets/image/thankyou/thankyou4.webp" alt="thankyou" className="w-100 thankyou_a" loading="lazy" />
                                        </div>
                                        <div className='thankyou_link mb-0'>
                                            <span className='thankyou_span'>Blogs</span>
                                            <h2 className='thankyou_h2'>Know more about our updates</h2>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ThankYou