import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelTerahash() {

      return (
            <>
                  <Helmet>
                        <title>3D Rendering for Terahash Solutions | aBox Agency</title>
                        <meta name="title" content="3D Rendering for Terahash Solutions | aBox Agency" />
                        <meta name="description" content="Abox Agency created realistic 3D renders for Terahash Solutions’ crypto mining hardware. We showcased the advanced technology and detailed design of their ASIC miners to enhance online sales and marketing." />
                        <meta name="keywords" content="Abox Agency, 3D rendering, product modeling, crypto mining hardware, ASIC miners, Bitcoin mining, 3D product visualization, cryptocurrency, eCommerce, mining equipment, realistic 3D models, mining solutions, product design, Bitcoin hosting, mining support services, high-tech visuals, crypto industry, 3D product design, digital marketing for tech, product presentation, eCommerce visuals, realistic 3D render, tech rendering, Terahash Solutions" />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/terahash-solutions/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Terahash Solutions</span> <br /> <span className="text_black">Changing Crypto <br /> Mining with Realistic <br /> Renders</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/terahash/terahash1.jpg"
                                    alt="Terahash" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Terahash Solutions specialises in selling and supporting ASIC miners for Bitcoin mining. We worked together to create realistic 3D renderings of their mining hardware. The goal was to showcase their products in an appealing and lifelike way to boost their online presence and attract customers. Terahash Solutions needed high-quality 3D visuals to show their mining hardware in a realistic and detailed way. The aim was to make the products look impressive and engaging to drive online sales.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Product Visualization</li>
                                                                  <li>Texturing and Material Design</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Cryptocurrency Solutions</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <video className="h-100" width={"100%"} autoPlay loop muted playsInline>
                                                      <source src="https://d1cihhbb125h48.cloudfront.net/1736499462.mp4" type="video/mp4" />
                                                </video>
                                          </div>
                                          <div className="col-md-6">
                                                <div className="h-100">
                                                      <img src="/assets/image/label/terahash/terahash3.jpg" className="h-100 w-100" alt="Terahash" />
                                                </div>
                                          </div>
                                    </div>

                                    <div className="mt_30">
                                          <img src="/assets/image/label/terahash/terahash4.jpg" className="w-100" alt="Terahash" />
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We designed 3D models and renders of Terahash’s mining equipment. By paying close attention to details, textures, and materials, we made sure the final visuals represented the advanced technology and durability of the miners. Our focus was on creating realistic images to make the products stand out. The 3D renders helped enhance Terahash Solutions’ online presence and marketing efforts. The realistic images showcased the quality and technology of the products, making them more appealing to customers and helping Terahash stand out in the competitive crypto mining market.</p>
                                    </div>
                                    <div className="row gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/terahash/terahash5.jpg" className="w-100" alt="Terahash" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/terahash/terahash6.jpg" className="w-100" alt="Terahash" />
                                                </div>
                                          </div>
                                    </div>

                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/terahash/terahash7.jpg" className="w-100" alt="Terahash" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div className="gap_remove">
                                                      <img src="/assets/image/label/terahash/terahash8.jpg" className="w-100" alt="Terahash" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="mt_30 mb-4">
                                          <img src="/assets/image/label/terahash/terahash9.jpg" className="w-100" alt="Terahash" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}