import React from 'react'

export default function CROTick() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className='cro_tick' width="20" height="20" viewBox="0 0 20 20" fill="none">
                <rect width="20" height="20" rx="3" />
                <path d="M6 10.2308L8.52632 13L14 7" stroke="#004737" stroke-width="1.5" />
            </svg>
        </>
    )
}