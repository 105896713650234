import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelRas() {

    return (
        <>
            <Helmet>
                <title>Label Design & 3D | RAS Luxury Skincare | aBox Agency</title>
                <meta name="title" content="Label Design & 3D | RAS Luxury Skincare | aBox Agency" />
                <meta name="description" content="Discover our portfolio showcasing the complete label design and 3D product presentation for RAS Luxury Skincare. As India's first 'farm to face' venture, RAS Luxury Skincare offers 100% pure, natural products. Explore how aBox Agency creates impactful and visually stunning designs to elevate skincare brands." />
                <meta name="keywords" content="RAS Luxury Skincare, label design, 3D product presentation, web design, aBox Agency, skincare branding, natural skincare, farm to face, pure skincare products, eco-friendly skincare, organic skincare, sustainable skincare, luxury skincare, user experience (UX), ecommerce website design, award-winning design, San Francisco web design agency, California web design agency, product labels, product design, impactful branding, creative designs, cosmetic packaging, beauty products, innovative design, visual branding, product visuals, brand identity, skincare portfolio, design portfolio, branding agency" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/ras-beauty/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title ras_title mb-0"><span>RAS Luxury Oils</span> <br /> <span className="text_black">Nature's Essence, <br /> Elevated in 3D</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/ras/ras1.webp"
                        alt="Ras" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">For RAS Luxury Oils, aBox Agency delivered stunning 3D renders and intricate product modeling. Our work highlights RAS's dedication to natural luxury, capturing the purity and essence of their botanical ingredients through striking visuals.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>3D Render</li>
                                            <li>3D Modeling</li>
                                            <li>Product Visualization</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Beauty and Skincare</li>
                                            <li>E-Commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/ras/ras2.webp" className="w-100" alt="Ras" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="h-100">
                                    <video className="ras_video1 h-100" width={"100%"} autoPlay loop muted playsInline>
                                        <source src="/assets/image/label/ras/ras3.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/ras/ras4.webp" className="w-100" alt="Ras" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div className="h-100">
                                    <video className="ras_video2 h-100" width={"100%"} autoPlay loop muted playsInline>
                                        <source src="/assets/image/label/ras/ras5.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/ras/ras6.webp" className="w-100" alt="Ras" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/ras/ras7.webp" className="w-100" alt="Ras" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/ras/ras8.webp" className="w-100" alt="Ras" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}