import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioTerahash() {

    return (
        <>
            <Helmet>
                <title>Terahash Solution’s Digital Transformation | Success Stories: Crypto Mining Devices | aBox Agency</title>
                <meta name="title" content="Terahash Solution’s Digital Transformation | Success Stories: Crypto Mining Devices | aBox Agency" />
                <meta name="description" content="Discover how aBox transformed Terahash Solution’s online presence. Our collaboration resulted in a sleek new website that highlights their innovative crypto mining devices, merging tech excellence with superior user experience." />
                <meta name="keywords" content="Terahash Solution, aBox, Crypto Mining, Cryptocurrency website, Web3 Website, Blockchain Website, Crypto Devices, Blockchain E-commerce, Web Agency, Webflow, Web Development, Website Revamp, Client Success Stories, Collaborations, Valued Partnerships, Mining Devices, 3D Render, Product Modelling, Success Stories, Web Design, Creative Solutions, aBox Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/terahash-solutions/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_terahash">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Terahash Solutions</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724409792.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Terahash Solution <br />
                                        <span className="terahash_title">
                                            Powering Up Your Crypto Mining Game
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Terahash Solution, a leader in Crypto Mining devices, needed a website that truly reflected their cutting-edge technology and innovation. They turned to us, aBox, for a comprehensive website revamp. We rebuilt their website on Webflow, integrating all necessary features and e-commerce capabilities. Additionally, we provided 3D renderings and modeling for their products, showcasing their advanced crypto mining devices in all their glory. Today, Terahash Solution’s revamped website is a testament to our collaborative effort and dedication to excellence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.terahashsolutions.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="terahash_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-commerce</li>
                                                <li>Webflow</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>3D Render</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="terahash_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Crypto Mining Devices</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_terahash_logo">
                                <img src="/assets/image/portfolios/terahash/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/terahash/terahash1.webp" className="w-100" alt="Terahash Solutions" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title terahash_title">Transforming Terahash Solution’s Digital Frontier</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        At aBox, our team of experts dedicated themselves to transforming Terahash Solution’s online presence. We meticulously designed and developed a new website on Webflow, ensuring it was both visually appealing and functionally robust. Our 3D renderings and product modeling added a dynamic layer, providing potential customers with a detailed view of their state-of-the-art crypto mining devices.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/terahash/terahash2.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/terahash/terahash3.webp"
                                            alt="Terahash Solutions" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/terahash/terahash4.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/terahash/terahash5.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/terahash/terahash6.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/terahash/terahash7.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/terahash/terahash8.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                                <div className="mt_20">
                                    <img src="/assets/image/portfolios/terahash/terahash9.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect terahash_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/terahash/terahash10.webp"
                                            alt="Terahash Solutions" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/terahash/terahash11.webp"
                                            alt="Terahash Solutions" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title terahash_title">Merging Cutting-Edge Tech with Superior User Experience</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Terahash Solution, renowned for their innovation in crypto mining, now boasts a website that matches their technological prowess. Visit <a href="https://www.terahashsolutions.com/" target="_blank" className="official_link omsweets_text">www.terahashsolutions.com</a> to explore their advanced devices and experience a seamless ecommerce journey, representing quality and cutting-edge technology in the crypto mining industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/terahash/terahash12.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/terahash/terahash13.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/terahash/terahash14.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/terahash/terahash15.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/terahash/terahash16.webp"
                                        alt="Terahash Solutions" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title terahash_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/terahash/terahash17.webp" className="w-100" alt="Terahash Solutions color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/terahash/terahash18.webp" alt="Terahash Solutions font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">The new website with 3D renderings and product modeling is exceptional. aBox’s expertise in WebFlow and e-commerce integration has provided us with a robust online platform that matches our cutting-edge technology. Excellent work!</p>
                                    <h3 className="title_color padd_s terahash_title">Terahash Solution</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Terahash Solution</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg kutum_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/kutum-ayurveda" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}