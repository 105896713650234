import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioCodifi() {

    return (
        <>
            <Helmet>
                <title>Codifi’s Digital Makeover | Field Data Management & Financial Software | aBox Agency</title>
                <meta name="title" content="Codifi’s Digital Makeover | Field Data Management & Financial Software | aBox Agency" />
                <meta name="description" content="See how aBox and Codifi transformed business efficiency with a new WebFlow website. Our partnership created a top-notch online platform showcasing Codifi’s field data management and financial software services." />
                <meta name="keywords" content="Codifi, aBox, Field Data Management, Financial Software, Business Services, WebFlow, Web Agency, Web Development, Client Success Stories, Collaborations, Partnerships, Business Solutions, Success Stories, Web Design, Creative Solutions, aBox Agency, Online Portfolio, Digital Transformation, Responsive Design, SEO, UI/UX, Visual Identity" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/codifi/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_codifi">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Codifi</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724145645.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod omsweets_text">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Codifi <br />
                                        <span className="codifi_title">
                                            Boosting Business with Smart Solutions
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Codifi, a top name in field data management and financial software services, needed a fresh digital presence. They turned to us, aBox, for a full website makeover using WebFlow. We created a sleek, professional site that shows off their expertise and innovative services. Now, Codifi’s website is a shining example of what we can achieve together.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.codifi.in/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="codifi_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Webflow</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="codifi_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Field Data Management</li>
                                                <li>Service Based Website</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_codifi_logo">
                                <img src="/assets/image/portfolios/codifi/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/codifi/codifi1.webp" className="w-100" alt="Codifi" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title codifi_title">Transforming Codifi’s Online World</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our team at aBox poured their skills into making Codifi’s online presence pop. Using WebFlow, we designed a smooth, engaging site that highlights their top-notch field data management and financial software services. The new site isn’t just pretty—it’s a powerful tool that shows Codifi’s commitment to quality and innovation.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/codifi/codifi2.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/codifi/codifi3.webp"
                                            alt="Codifi" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/codifi/codifi4.webp"
                                            alt="Codifi" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/codifi/codifi5.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/codifi/codifi6.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/codifi/codifi7.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/codifi/codifi8.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="sweet_delect pb-0 strenth_bg codifi_banner_bg mt_20">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/codifi/codifi9.webp"
                                            alt="Codifi" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/codifi/codifi10.webp"
                                            alt="Codifi" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/codifi/codifi11.webp"
                                            alt="Codifi" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title codifi_title">Powering Businesses with Digital Smarts</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Codifi is known for its stellar field data management and financial software. Now, with a fresh and modern website, they’re ready to impress online. Check out <a href="https://www.codifi.in/" target="_blank" className="official_link omsweets_text">www.codifi.in</a> to see how Codifi is leading the way with smart digital solutions.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/codifi/codifi12.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/codifi/codifi13.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/codifi/codifi14.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/codifi/codifi5.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/codifi/codifi16.webp"
                                        alt="Codifi" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title codifi_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/codifi/codifi17.webp" className="w-100" alt="codifi color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/codifi/codifi18.webp" alt="codifi font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Our old website did a disservice to our top-notch services. aBox swooped in and completely revamped it using Webflow. The results are amazing! The new site is sleek, professional, and showcases our expertise perfectly. We're already seeing a positive impact on our business.</p>
                                    <h3 className="title_color padd_s codifi_title">Codifi</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Codifi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg terahash_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/terahash-solutions" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}