import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioChakNow() {

    return (
        <>
            <Helmet>
                <title>ChakNow Website Development | Custom Shopify Design | aBox Agency</title>
                <meta name="title" content="ChakNow Website Development | Custom Shopify Design | aBox Agency" />
                <meta name="description" content="Find out how Shopify development and custom UI/UX design by aBox revolutionized ChakNow's online presence. This project highlights modern snacking, seamless navigation, and enhanced customer engagement." />
                <meta name="keywords" content="ChakNow, aBox Agency, Anand sweets, custom UI/UX design, Shopify development, South Indian snacks, modern snacking, online snack shop, responsive design, eCommerce development, Shopify experts, digital branding, brand identity, user-friendly design, South Indian delicacies, brand heritage, food eCommerce, product browsing, online presence, Shopify solutions, digital transformation, customer engagement, SEO optimization, heritage snacks, aBox portfolio, intuitive navigation, online snack sales, Shopify theme customization, Shopify store setup, Shopify performance, online brand visibility, snack industry eCommerce, intuitive user experience, product-focused UI, seamless user journey, digital store experience, mobile-friendly design, visual storytelling, Shopify app integration, Shopify frontend development, immersive shopping experience, custom navigation, product display optimization, modern Shopify design, conversion-focused design, interactive Shopify store, engaging UX, Shopify design trends, user-centric Shopify store, Shopify design agency" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/chaknow/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_chaknow">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name gpulla_text2_bg omsweets_text">Chak Now</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/G.Pulla_Reddy_Sweets.mp4" type="video/mp4" />
                                    </video>*/}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name gpulla_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title chaknow_text mb-0">
                                        Chak Now <br />
                                        <span className="chaknow_title">
                                            Digital presence for Ethnic Indian Flavor
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content chaknow_text mb-3">We updated ChakNow's digital presence with a custom UI/UX design that adds a modern twist to traditional South Indian snacks. Our design prioritized increased user engagement, seamless browsing, and a smooth purchase experience.</p>
                                    <p className="services_content chaknow_text">We developed a Shopify website with a focus on intuitive navigation and product highlights. The new site lets customers explore South Indian delicacies with ease, blending convenience with brand heritage to appeal to a wider audience.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://chaknow.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="chaknow_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list chaknow_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="chaknow_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list chaknow_list">
                                                <li>Snacking Foods</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_chaknow_logo">
                                <img src="/assets/image/portfolios/chaknow/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/chaknow/chaknow1.jpg" className="w-100" alt="Chaknow" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title chaknow_title">Making a Digital Experience that is Delicious</h2>
                                    <p className="services_pera chaknow_text font_regular mb-0">
                                        Our team revamped ChakNow's platform with a modern, responsive design and seamless navigation, ensuring every user finds their perfect snack effortlessly. The site now reflects the brand's vibrant flavors and heritage.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/chaknow/chaknow2.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow3.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow4.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/chaknow/chaknow5.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow6.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/chaknow/chaknow7.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow8.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title chaknow_title">Increased engagement, improved browsing, smoother sales</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera chaknow_text mb-0">
                                        With improved customer experience and optimized navigation, ChakNow has seen increased engagement and streamlined sales. Explore the deliciously convenient website at <a href="https://chaknow.com/" target="_blank" className="portfolio_a gpulla_title">chaknow.com.</a>.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/chaknow/chaknow9.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/chaknow/chaknow10.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow11.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow12.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/chaknow/chaknow13.jpg"
                                        alt="Chaknow" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title chaknow_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/chaknow/chaknow14.jpg" className="w-100" alt="G Pulls Reddy color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/chaknow/chaknow15.jpg" alt="Chaknow font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="chaknow_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s chaknow_title">AuroLab</h3>
                                    <p className="font_medium services_pera chaknow_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg vijayalakshami_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/vijaya-lakshmi-silks" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}