import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioVijayaLaxmiSilk() {

    return (
        <>
            <Helmet>
                <title></title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/vijaya-lakshmi-silks/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_vijayalakshmi">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name gpulla_text2_bg omsweets_text">Vijaya Lakshmi Silk</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/G.Pulla_Reddy_Sweets.mp4" type="video/mp4" />
                                    </video>*/}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name gpulla_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title chaknow_text mb-0">
                                        Vijaya Lakshmi Silk <br />
                                        <span className="chaknow_title">
                                            Revamping a Century-Old Legacy with Modern Design
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content chaknow_text mb-3">At aBox, we executed a comprehensive Figma design and Shopify development for G. Pulla Reddy Sweets, enhancing their online presence while honoring their rich heritage. Our goal was to create a user-friendly platform that showcases their traditional delicacies effectively.</p>
                                    <p className="services_content chaknow_text">We implemented a visually appealing design that resonates with the brand's values of purity and authenticity. By integrating seamless navigation and optimized product displays, we ensured that customers could easily explore and appreciate the legacy of G. Pulla Reddy Sweets.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.vijayalakshmisilks.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="chaknow_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list chaknow_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="chaknow_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list chaknow_list">
                                                <li>Confectionery sweets</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_chaknow_logo">
                                <img src="/assets/image/portfolios/vijayalakshmi/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi1.jpg" className="w-100" alt="vijaya Lakshmi" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title chaknow_title">Improving User Behavior and Brand Heritage</h2>
                                    <p className="services_pera chaknow_text font_regular mb-0">
                                        We enhanced the website's design and functionality by incorporating user-centric elements and storytelling features. This included improved navigation, engaging product showcases, and a compelling representation of the brand's history and values.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi2.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi3.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi4.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi5.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi6.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi7.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi8.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi9.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title chaknow_title">Bringing Tradition to the Online World</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera chaknow_text mb-0">
                                        The revamped website now attracts a wider audience, increasing engagement and sales. Explore the live site to experience the perfect blend of tradition and modernity <a href="https://www.vijayalakshmisilks.com/" target="_blank" className="portfolio_a gpulla_title">Vijaya Lakshami Silk</a>.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi10.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi11.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi12.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi13.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi14.jpg"
                                        alt="Vijaya Lakshmi" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title chaknow_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi15.jpg" className="w-100" alt="Vijaya Lakshmicolor palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/vijayalakshmi/vijayalakshmi16.jpg" alt="Vijaya Lakshmi font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="chaknow_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s chaknow_title">AuroLab</h3>
                                    <p className="font_medium services_pera chaknow_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg instant_price_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/instant-cash-price" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}