import React from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import { Navigation, Pagination, Autoplay } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import DiscoverArrow from "../component/icons/DiscoverArrow";

export default function AboutUs() {

    const clients = [
        {
            clientHref: '/portfolio/yogabar',
            clientImgSrc: '/assets/image/clients/yogabar.svg',
            clientImgAlt: 'yogabar',
            clientInfoOne: 'Nutrient-Rich Protein',
            clientInfoTwo: 'Foods',
        },
        {
            clientHref: '/portfolio/anand-sweets',
            clientImgSrc: '/assets/image/clients/anand.svg',
            clientImgAlt: 'anand sweets',
            clientInfoOne: `Royal India's Traditional`,
            clientInfoTwo: 'Sweet Delights.',
        },
        {
            clientHref: '/portfolio/hamdard',
            clientImgSrc: '/assets/image/clients/humdard.svg',
            clientImgAlt: 'hamdard',
            clientInfoOne: 'Pinnacle Unani Medicine',
            clientInfoTwo: 'in India',
        },
        {
            clientHref: '/portfolio/om-sweets',
            clientImgSrc: '/assets/image/clients/om.svg',
            clientImgAlt: 'om sweets',
            clientInfoOne: 'Traditional sweet treats with',
            clientInfoTwo: 'casual dining',
        },
        {
            clientHref: '/portfolio/bayla',
            clientImgSrc: '/assets/image/clients/bayla_skin.svg',
            clientImgAlt: 'bayla skin',
            clientInfoOne: `Nature's Science for`,
            clientInfoTwo: 'Radiant Skin',
        },
        {
            clientHref: '/portfolio/dr-vaidya',
            clientImgSrc: '/assets/image/clients/dr_vaidyas.svg',
            clientImgAlt: 'dr vaidyas',
            clientInfoOne: 'Ayurvedic Stamina and',
            clientInfoTwo: 'Health Supplements',
        },
        {
            clientHref: '/portfolio/within-beauty',
            clientImgSrc: '/assets/image/clients/within_beauty.svg',
            clientImgAlt: 'within beauty',
            clientInfoOne: 'Ceramide-powered skincare for',
            clientInfoTwo: 'healthy skin',
        },
        {
            clientHref: '/portfolio/uppercase',
            clientImgSrc: '/assets/image/clients/uppercase.svg',
            clientImgAlt: 'uppercase',
            clientInfoOne: 'Stylish and sustainable bags for',
            clientInfoTwo: 'the eco-conscious traveler',
        },
        {
            clientHref: '/portfolio/technosport',
            clientImgSrc: '/assets/image/clients/technosport.svg',
            clientImgAlt: 'techno sport',
            clientInfoOne: 'Athletic and Leisure Wear',
            clientInfoTwo: 'Redefining Fashion',
        },
        {
            clientHref: 'https://indiasweethouse.in/',
            clientImgSrc: '/assets/image/clients/india_sweets.svg',
            clientImgAlt: 'india sweets',
            clientInfoOne: 'Celebrates sweet flavors through',
            clientInfoTwo: 'traditional sweets',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/aurolab',
            clientImgSrc: '/assets/image/clients/aurolab.svg',
            clientImgAlt: 'aurolab',
            clientInfoOne: 'Visionary Ophthalmic',
            clientInfoTwo: 'Solutions Worldwide',
        },
        {
            clientHref: '/portfolio/fone-network',
            clientImgSrc: '/assets/image/clients/fonnetwork.svg',
            clientImgAlt: 'fone network',
            clientInfoOne: 'Mobile Blockchain',
            clientInfoTwo: 'and Cryptocurrency',
        },
        {
            clientHref: '/portfolio/nourysh',
            clientImgSrc: '/assets/image/clients/nourysh.svg',
            clientImgAlt: 'nourysh',
            clientInfoOne: 'Health-Optimized Nutrient',
            clientInfoTwo: 'Formulas',
        },
        {
            clientHref: '/portfolio/maa-and-baby',
            clientImgSrc: '/assets/image/clients/maa_baby.svg',
            clientImgAlt: 'maa & baby',
            clientInfoOne: 'Baby products for your',
            clientInfoTwo: 'precious moments.',
        },
        {
            clientHref: 'https://www.gulabrewri.com/',
            clientImgSrc: '/assets/image/clients/gulab.svg',
            clientImgAlt: 'gulab',
            clientInfoOne: 'Delicious Indian sweets',
            clientInfoTwo: 'and snacks',
            target: '_blank',
        }
    ];

    const ClientsCard = ({ clientHref, target, clientImgSrc, clientImgAlt, clientInfoOne, clientInfoTwo }) => (
        <a href={clientHref} target={target} className="image_color bg-white">
            <div className="product_logo">
                <div className="logo_box">
                    <img src={clientImgSrc} loading="lazy" alt={clientImgAlt} />
                </div>
                <p className="d-md-block d-none">{clientInfoOne} <br className="d-lg-block d-none" /> {clientInfoTwo}</p>
                <a className="d-md-flex d-none" href={clientHref} target={target}>
                    <span className="discover_span">DISCOVER</span>
                    <span className="logo_span">
                        <DiscoverArrow />
                    </span>
                </a>
            </div>
        </a>
    );

    return (
        <>
            <Helmet>
                <title>About Us and Services | aBox Agency's Story</title>
                <meta name="title" content="About Us and Services | aBox Agency's Story" />
                <meta name="description" content="Explore the journey and services of aBox Agency. Learn about our background, values, and the comprehensive web solutions we offer to help businesses thrive in the digital world." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, About Us, Web Agency Story, Our Journey, About aBox Agency, Company Background. Our Values, Web Services, Comprehensive Solutions, Digital Success, Thrive Online, Web Design Services, Web Development, Creative Agency, Digital Marketing, Client-Centric Approach, Expert Team, Innovative Solutions, Business Growth, Online Presence, Custom Web Solutions, Web Strategy, Client Success, Creative Excellence, Web Industry, Transformative Services" />
                <link rel="canonical" href="https://www.abox.agency/about/" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail aboutus_title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-11">
                                <h1 className="portfolio_heading color_light">We are a dynamic team blending strategy, marketing, design, and digital expertise to deliver exceptional results.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* story section */}
            <div className="section_detail story_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11">
                            <p className="about_p">Our Story</p>
                            <h2 className="career_h2 color_dark mb-0">Our story is one of passion, dedication, and unwavering commitment. It all began with a vision to make a difference. Through our relentless pursuit of excellence and a customer-centric approach, we've evolved into a trusted partner. Join us on this journey as we continue to create meaningful experiences and surpass expectations.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="container-fluid p-0">
                <div>
                    <img src="/assets/image/team.webp" alt="Team" loading="lazy" className="w-100" />
                </div>
            </div>

            {/* values section */}
            <div className="section_detail values_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 image_padding">
                            <p className="about_p">Our Principals</p>
                            <h2 className="career_h2 mb-0 color_dark">Here are the four rules we stick to to safeguard you in these moving conditions:</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 image_padding">
                            <h3 className="heading_h2 color_dark">We have areas of strength for</h3>
                            <p className="image_p margin_top">Our philosophy emphasizes blurring boundaries between work and play, driven by curiosity and creativity. We push beyond our comfort zones, embracing the unknown and unlocking endless possibilities for true innovation.</p>
                        </div>
                        <div className="col-lg-5 offset-lg-1 image_padding">
                            <h3 className="heading_h2 color_dark">We appreciate Learning.</h3>
                            <p className="image_p margin_top">Learning is our passion, and we continuously refine our skills in design, development, and copywriting. We embrace new platforms and features, believing that lifelong learners are the epitome of greatness. Join us on this journey of constant growth and innovation.</p>
                        </div>
                        <div className="col-lg-5 tab_padding">
                            <h3 className="heading_h2 color_dark">We abandon inner selves</h3>
                            <p className="image_p margin_top">Our culture encourages constructive criticism, challenging ideas for better outcomes, and promoting agreement and alignment. The goal is to produce exceptional work driven by purpose and optimize every moment to deliver excellence.</p>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <h3 className="heading_h2 color_dark">We stand with each other.</h3>
                            <p className="image_p margin_top">Every day, we build websites, collaborate, connect, and appreciate our clients and colleagues. We stand united, committed to the journey, and ready to support and thrive together for the long run.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* team section */}
            <div className="section_detail team_section">
                <div className="container">
                    <div className="row border_light">
                        <div className="col-lg-9 padding_team">
                            <p className="about_p color_light">The Mastermind</p>
                            <h2 className="heading_h2">Meet multi-talented people who are passionate about their craft.</h2>
                        </div>
                    </div>
                    <div className="row team_padding1">
                        <div className="col-lg-4 col-md-6">
                            <img src="/assets/image/team/ceo.webp" alt="CEO" loading="lazy" className="w-100" />
                            <div className="team_margin d-flex align-items-center justify-content-between">
                                <h3 className="image_h3 color_light">Raj Ladumor</h3>
                                <span>
                                    <img src="/assets/image/linkedin.svg" alt="linkedin" />
                                </span>
                            </div>
                            <p className="team_position">Co-founder and Creative Director</p>
                        </div>
                        <div className="col-lg-4 col-md-6 team_padding2 mob_image_padding">
                            <img src="/assets/image/team/cto.webp" alt="CTO" loading="lazy" className="w-100" />
                            <div className="team_margin d-flex align-items-center justify-content-between">
                                <h3 className="image_h3 color_light">Shani Sheladiya</h3>
                                <span>
                                    <img src="/assets/image/linkedin.svg" alt="linkedin" />
                                </span>
                            </div>
                            <p className="team_position">CTO & Co-founder</p>
                        </div>
                        <div className="col-lg-4 col-md-6 team_padding3">
                            <img src="/assets/image/team/coo.webp" alt="COO" loading="lazy" className="w-100" />
                            <div className="team_margin d-flex align-items-center justify-content-between">
                                <h3 className="image_h3 color_light">Nayan Ahir</h3>
                                <span>
                                    <img src="/assets/image/linkedin.svg" alt="linkedin" />
                                </span>
                            </div>
                            <p className="team_position">Chief Operating Officer (COO)</p>
                        </div>
                        {/* tab_image_padding */}
                    </div>
                </div>
            </div>

            {/* company section */}
            <div className="section_detail company_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 company_margin">
                            <h2 className="heading_h2 color_dark">Trusted with more than
                                150 Companies since 2016</h2>
                        </div>
                    </div>
                    <div className="product_section">
                        {
                            clients.map((i, index) =>
                                <ClientsCard key={index} {...i} />
                            )
                        }
                        <div className="product_logo">
                            <h4 className="logo_h4">We back companies. <br /> But we believe in <br /> humans.</h4>
                            <div className="logo_btn">
                                <a href="/clients" className="btn submit_btn">View More
                                    <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                                    </svg>
                                    <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                        <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* slider section */}
            {/* <div className="section_detail slider_section position-relative">
                <div className="container-fluid p-0">
                    <div className="overflow-hidden padding_left">
                        <div className="d-flex align-items-center mb-md-0 mb-5">
                            <h2 className="about_p font_regular">10 - 7 at the studio and beyond</h2>
                        </div>
                        <Swiper                          
                            speed={2000}
                            spaceBetween={0}                           
                            autoplay={false}
                            navigation={{
                                nextEl: ".aboutus_next",
                                prevEl: ".aboutus_prev",
                            }}
                            pagination={false}
                            draggable={true}
                            loop={true}
                            breakpoints={{
                                578: {
                                    slidesPerView: 1.5,
                                },
                                768: {
                                    slidesPerView: 1.8,
                                },
                                820: {
                                    slidesPerView: 2,
                                },
                                991: {
                                    slidesPerView: 2.2,
                                },
                                1024: {
                                    slidesPerView: 2,
                                },
                                1366: {
                                    slidesPerView: 2.3,
                                },
                                1440: {
                                    slidesPerView: 2.4,
                                },
                                1600: {
                                    slidesPerView: 2.5,
                                },
                                1920: {
                                    slidesPerView: 3,
                                },
                            }}
                            modules={[Navigation, Pagination, Autoplay]}
                            className="mySwiper swiper_slide"
                        >
                            <SwiperSlide>
                                <img src="/assets/image/image1.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/image/image2.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/image/image3.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/image/image4.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/image/image5.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/image/image6.webp" loading="lazy" alt="about" />
                            </SwiperSlide>
                           
                            <div className='d-flex align-items-center slider_btn'>
                                <div className='aboutus_prev me-2'>
                                    <img src="/assets/image/sldier_prev.svg" loading="lazy" alt="slider prev" />
                                </div>
                                <div className="aboutus_next">
                                    <img src="/assets/image/slider_next.svg" loading="lazy" alt="slider next" />
                                </div>
                            </div>                            
                        </Swiper>
                    </div>
                </div>
            </div> */}

            <LetsTalk />

            <Footer />
        </>
    );
}