import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
// import Marquee from "react-fast-marquee";
// import useScrollRedirect from "../hooks/useScrollRedirect";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioAnandsweets() {

    // useScrollRedirect('/portfolio/yogabar')

    return (
        <>
            <Helmet>
                <title>Anand Sweet Showcase | Success Stories: Anand Sweet | aBox Agency</title>
                <meta name="title" content="Anand Sweet Showcase | Success Stories: Anand Sweet | aBox Agency" />
                <meta name="description" content="Explore the delightful Anand Sweet showcase brought to life by aBox Agency. Dive into the success story of Anand Sweet and how our creative solutions contributed to their online presence." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Anand Sweet, Web Development, Shopify store, Success Stories, Anand Sweet Success, aBox Agency, Creative Solutions, Online Presence, Web, Success Story, Client Showcase, Sweet Shop Website, Web Design Excellence, Digital Transformation, Client Success Story,E-commerce Solutions, Web Development, Anand Sweet Online, Web Agency Services, Creative Web, Solutions, Client Testimonial, Business Growth, Online Sweet Store, Web Design Showcase, Success Through Design, Website Transformation, Box Client Stories, Sweet Shop Branding" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/anand-sweets/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/anand_sweets.jpg" />
            </Helmet>

            <Header />

            <div className="page_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name">Anandsweets</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894083.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name writing_mod">Published in 2021</p>
                                    <h1 className="services_title light_color mb-0">Anand Sweets <br /> <span className="title_color">Elevating Online Delights to New Heights</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content light_color">Anand Sweets, a popular Indian sweets brand, wanted to revamp their e-commerce website. aBox, a Shopify web agency, collaborated with them to create a sophisticated Indian sweets Shopify website. Our team worked tirelessly to create a new look and feel for the website that was in keeping with the Sweet Store brand legacy. Over the last three years, aBox Agency has provided user-friendly designs, product ideas, product and content placement, and consultation. We are proud to have been a part of this project.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.anandsweets.in/" target="_blank" className="d-flex align-items-center link_btn anandlink_bg">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="title_color services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="title_color services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list">
                                                <li>Sweets and Beverages</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner anand_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/anandsweets/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/anandsweets/product.webp" className="w-100" alt="anandsweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title light_color">Building the foundation of a tasty brand:</h2>
                                    <p className="services_pera light_color font_regular mb-0">Our technical experts carefully tailored features to suit Anand Sweet's unique needs. They made a smooth online shopping experience and a user-friendly interface to display the variety of delicious offerings.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/anandsweets/anandsweets1.webp"
                                            alt="anandsweets" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/anandsweets/anandsweets2.webp"
                                            alt="anandsweets" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/anandsweets/anandsweets3.webp"
                                            alt="anandsweets" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/anandsweets/anandsweets4.webp"
                                            alt="anandsweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/anandsweets/digital_brand.webp" alt="anandsweets" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect sweet_delect">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets5.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets6.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets7.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title light_color">Enhancing the brand's essence with finesse:</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera light_color mb-0">Anand Sweet offers a delightful journey, blending the heritage of Indian sweets with modern technology. Visit <a href="https://www.anandsweets.in/" target="_blank" className="portfolio_a">www.anandsweets.in</a> for a delightful mix of taste and sophistication.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets8.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets9.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets10.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/anandsweets/anandsweets11.webp"
                                        alt="anandsweets" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/anandsweets/anandsweets12.webp"
                                    alt="anandsweets" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand banner */}
                {/* <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/anandsweets/digital_brand.webp" className="w-100" alt="anandsweets" />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Digital brand section */}
                {/* <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title light_color">Achieving goals through seamless user-centric brand experiences.</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera light_color mb-0">Anand Sweet's online presence combines their legacy with technical expertise, creating a digital masterpiece that embodies their brand essence. With every click and order, Anand Sweet enchants sweet enthusiasts seeking the ultimate indulgence, leaving a lasting impression.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* vertical slide section */}
                {/* <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40">
                            <div className="d-md-flex">
                                <div className="slide_section pe-md-3">
                                    <img src="/assets/image/product/anandsweets/image10.webp" alt="anandsweets" />
                                    <img src="/assets/image/product/anandsweets/image11.webp" alt="anandsweets" />
                                    <img src="/assets/image/product/anandsweets/image12.webp" alt="anandsweets" className="d-block d-md-none" />
                                    <img src="/assets/image/product/anandsweets/image13.webp" alt="anandsweets" className="d-block d-md-none" />
                                </div>
                                <div className="slide_section slide_section_reverse ps-md-3 d-md-block d-none">
                                    <img src="/assets/image/product/anandsweets/image12.webp" alt="anandsweets" />
                                    <img src="/assets/image/product/anandsweets/image13.webp" alt="anandsweets" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title light_color">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/anandsweets/palette.webp" className="w-100" alt="anandsweets color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/anandsweets/playfair.webp" alt="anandsweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="light_color perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 className="title_color padd_s">Ankush Dadu</h3>
                                    <p className="font_medium services_pera light_color mb-0 padd_s">Founder of Anand Sweet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/yogabar" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}