import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelHealingHands() {

    return (
        <>
            <Helmet>
                <title>Healing Hands portfolio | Leading Proctology Care Solutions with Advanced 3D Rendering and Design Expertise</title>
                <meta name="title" content="Healing Hands portfolio | Leading Proctology Care Solutions with Advanced 3D Rendering and Design Expertise" />
                <meta name="description" content="Our collaboration with aBox resulted in the delivery of advanced 3D rendering, modeling, and holistic design solutions. From bottles to lubes and sprays, our services maintain the distinctive brand essence within the medical healthcare services industry, ensuring a seamless blend of innovation and expertise." />
                <meta name="keywords" content="Healing Hands, Proctology Care, 3D Rendering, Design Expertise, Piles Treatment, Fistula Solutions, Constipation Relief, aBox Agency, aBox design service, aBox label design, aBox 3D design services, aBox Collaboration, Packaging Design, Product Graphics, Infographics, Medical Healthcare Services, eCommerce, Innovative Treatments, Specialized Care Providers, Brand Essence, Advanced Proctology, Healthcare Industry, Design Services, Medical Solutions, Comprehensive Design, Visual Appeal, Healthcare Branding, Unique Brand Identity, 3D Modeling, Medical Innovations, Healthcare Packaging, Visual Storytelling, Industry Standards, Label Design, Labeling, Product Design, Blender, 3D Visualization, Packaging Innovation, Labeling Solutions, 3D Product Modeling, Blender 3D Design, Creative Packaging, Innovative Labeling, 3D Render Services, Product Labeling, Custom Packaging, Blender Rendering, 3D Graphics, Product Label Design, Packaging Concepts, Blender Models, 3D Design Techniques, Labeling Standards, Packaging Trends" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/healing-hands/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title mb-0"><span className="dark_green">Healing Hands</span> <br /> <span className="text_black">Advanced Proctology <br /> Care Expertise</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/healinghands/healinghands1.jpg"
                        alt="healing hands" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">A brand for specialized proctology care providers offers innovative treatments for piles, fistulas, constipation, and acidity. aBox, we successfully delivered 3D rendering, modeling, and the complete design of labels, infographics, and product graphics for their diverse product line, including bottles, lubes, and sprays, maintaining the unique brand essence.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Medical Healthcare Services</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ui section */}
                <div className="ui_section">
                    <div className="container">
                        <img src="/assets/image/label/healinghands/healinghands2.jpg"
                            alt="healing hands" className="img-fluid w-100" />

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands3.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands4.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands5.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/healinghands/healinghands6.jpg" className="w-100" alt="healing hands" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div className="h-100">
                                    <img src="/assets/image/label/healinghands/healinghands7.jpg" className="h-100 w-100" alt="healing hands" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row flex-column">
                                    <div className="col-12">
                                        <div>
                                            <img src="/assets/image/label/healinghands/healinghands8.jpg" className="w-100" alt="healing hands" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt_30">
                                        <div>
                                            <img src="/assets/image/label/healinghands/healinghands9.jpg" className="w-100" alt="healing hands" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands10.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands11.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>                           
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/healinghands/healinghands12.jpg" className="w-100" alt="healing hands" />
                        </div>
                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands13.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/healinghands/healinghands14.jpg" className="w-100" alt="healing hands" />
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}