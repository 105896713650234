import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelMolecule() {

      return (
            <>
                  <Helmet>
                        <title>3D Design & Modeling for Skincare Brand | aBox Agency</title>
                        <meta name="title" content="3D Design & Modeling for Skincare Brand | aBox Agency" />
                        <meta name="description" content="Abox Agency created realistic 3D renders and product models for Molecule53, a luxury skincare brand, showcasing their products across multiple platforms with lifelike textures and vibrant details." />
                        <meta name="keywords" content="3D rendering, product modeling, skincare design, luxury skincare, product presentation, 3D visualization, realistic product renders, skincare brand, multi-platform presentation, product mockups, luxury beauty, premium skincare, e-commerce visuals, 3D product design, texturing, product showcase, skincare packaging, Indian skin, high-quality ingredients, product visualisation, beauty brand design, digital marketing for skincare, realistic renders, 3D design, product placement, beauty industry, skincare solutions, luxury brand, skincare textures, eCommerce packaging, creative skincare design." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/molecule/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Molecule53</span> <br /> <span className="text_black">Luxury Skincare in <br /> Realistic 3D</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/molecule/molecule1.jpg"
                                    alt="Molecule" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Molecule53 offers premium skincare products designed specifically for Indian skin, combining high-quality ingredients for effective solutions for all skin types. We collaborated with them to create realistic 3D renders, modeling, and presentations of their skincare products for multiple platforms. The goal was to elevate their brand image and showcase their products in a visually striking and lifelike manner that resonates with their audience. Molecule53 needed high-end 3D visuals to enhance their online presence, reflecting the luxury and quality of their products.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Product Visualization</li>
                                                                  <li>Texturing</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Luxury Skincare</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule2.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule3.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule4.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule5.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule6.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule7.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule8.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div className="h-100">
                                                      <img src="/assets/image/label/molecule/molecule9.jpg" className="w-100 h-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <video className="h-100" width={"100%"} autoPlay loop muted playsInline>
                                                      <source src="https://d1cihhbb125h48.cloudfront.net/1736419049.mp4" type="video/mp4" />
                                                </video>
                                          </div>
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We created detailed 3D designs and models for the brand’s boxes and sachets, focusing on texture, presentation, and lifelike visuals. By applying realistic texturing and product placement, we ensured the final renders brought the brand’s creative and adventurous identity to life. Our work helped highlight the uniqueness of each tea blend through photorealistic visuals. Our work contributed to the brand’s enhanced digital presence, offering visually appealing packaging renders that captured the adventurous spirit of their handcrafted blends. The 3D designs played a crucial role in making the products stand out, improving customer engagement and appeal.</p>
                                    </div>
                                    <div className="row gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule10.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule11.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule12.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4 mb-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule13.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/molecule/molecule14.jpg" className="w-100" alt="Molecule" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}