import React, { useState } from "react";
import Header from "../component/Header";
import { Helmet } from "react-helmet";
import ShopifyLogo from "../component/icons/ShopifyLogo";
import StarIcon from "../component/icons/StarIcon";
import LeftArrow from "../component/icons/LeftArrow";
import RightArrow from "../component/icons/RightArrow";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ShopifyIcon from "../component/icons/ShopifyIcon";
import axios from "axios";
import { useNavigate } from "react-router";
import Footer from "../component/Footer";
import Arrow from "../component/icons/Arrow";
import ChatIcon from "../component/icons/ChatIcon";

export default function ShopifyFunnle() {

      const navigate = useNavigate();

      const [loading, setLoading] = useState(false);

      const [inputs, setInputs] = useState({
            name: "",
            companyName: "",
            website: "",
            email: "",
            aboutProject: ""
      })

      const [validations, setValidations] = useState({
            name: false,
            companyName: false,
            website: false,
            email: false,
            aboutProject: false
      })

      const handleInputChange = (event) => {
            const { name, value } = event.target
            setInputs((prev) => ({ ...prev, [name]: value }))
            setValidations((prev) => ({ ...prev, [name]: false }))
      }

      const sendData = async () => {
            if (loading) return;

            if (!inputs.name) {
                  setValidations({ ...validations, name: true })
                  return
            }

            // if (!inputs.companyName) {
            //       setValidations({ ...validations, companyName: true })
            //       return
            // }

            // if (!inputs.website) {
            //       setValidations({ ...validations, website: true })
            //       return
            // }

            if (!inputs.email || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email))) {
                  setValidations({ ...validations, email: true })
                  return
            }

            if (!inputs.aboutProject) {
                  setValidations({ ...validations, aboutProject: true })
                  return
            }

            setLoading(true);

            try {
                  await axios.post('https://formsubmit.co/ajax/65d554b105dcb5e0977b45ca2c6a2df7', {
                        _subject: 'Inquiry from aBox Shopify Funnel page',
                        _template: 'table',
                        name: inputs.name,
                        // companyName: inputs.companyName,
                        // website: inputs.website,
                        email: inputs.email,
                        projectAbout: inputs.aboutProject,
                  })
                  setInputs({ name: "", companyName: "", website: "", email: "", aboutProject: "" })
                  navigate('/thank-you')
            } catch (error) {
                  console.log(error)
            } finally {
                  setLoading(false);
            }
      }

      return (
            <>
                  <Helmet>
                        <title>Shopify store design & conversion optimization | aBox Agency</title>
                        <meta name="title" content="Shopify store design & conversion optimization | aBox Agency" />
                        <meta name="description" content="Grow your online business with aBox Agency. We offer expert Shopify services including store design, development, SEO, and marketing to help you achieve your eCommerce goals. Trusted by 200+ clients worldwide. Get a free quote today." />
                        <meta name="keywords" content="Shopify Agency, Shopify Partner, eCommerce Agency, eCommerce Development, Shopify Store Design, Shopify Migration, eCommerce SEO, Conversion Rate Optimization, CRO, Shopify Marketing, Shopify Support, Digital Marketing, Online Store, Business Growth, Website Design, Web Development, Brand Building, Shopify Experts, eCommerce Solutions, Boost Shopify Sales, Top Shopify Agency, Shopify Store Experts, Increase Sales & Conversions, Grow Your Shopify Store, eCommerce Consultant, Shopify Agency Services, Shopify eCommerce, Online Store Development, Shopify Store Optimization, eCommerce Growth Strategy, aBox Agency Shopify, Shopify Agency India, Surat Shopify Agency, Landing Page Design for Shopify, Shopify App Store Optimization, Reduce Shopify App Costs, Increase Shopify Conversion Rates, Improve Shopify SEO, Shopify Store Maintenance Services, Custom Shopify Themes, High-Converting Shopify Stores, aBox Agency Portfolio, Hire Shopify Experts, eCommerce Consulting Services, Shopify Growth Hacking" />
                        <link rel="canonical" href="https://www.abox.agency/abox-shopify/" />
                  </Helmet>

                  <div className="shopify_label d-flex align-items-center gap10 justify-content-center">
                        <ShopifyIcon />
                        <p className="font-inter">Official Shopify Partner</p>
                  </div>

                  <Header />

                  {/* Hero Section */}
                  <div className="d-flex flex-column gap-151 funnle-section">
                        <div className="container-inner">
                              <div className="store-row d-flex flex-lg-row flex-column align-items-center justify-content-between">
                                    <div className="store-col d-flex flex-column gap30">
                                          <div className="d-flex align-items-center gap-2">
                                                <ShopifyLogo />
                                                <div className="d-flex align-items-center gap2 star_icon">
                                                      <StarIcon />
                                                      <StarIcon />
                                                      <StarIcon />
                                                      <StarIcon />
                                                      <StarIcon />
                                                </div>
                                                <p className="mb-0 font-inter brand-title">90+ Brands & Still Counting</p>
                                          </div>
                                          <h1 className="main-title font-inter mb-0">We Built Shopify <br className="d-sm-block d-none" /> Stores that Convert</h1>
                                          <p className="pera_text font-inter mb-0">Achieve better sales, improved conversion rates, and effortless <br className="d-xl-block d-none" /> automation with Shopify stores tailored to grow your business, simplify <br className="d-xxl-block d-none" /> management, and maximize efficiency.</p>
                                          <div className="d-flex align-items-center gap10">
                                                <a href="https://vz3wn4o7lst.typeform.com/to/eHY3sxf9" target="_blank" className="btn submit_btn get-button font-inter font-inter">Audit my website</a>
                                                <a href="/contact" className="btn submit_btn get-button font-inter cro_btn">Talk to Us
                                                      <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width={17} height={23} viewBox="0 0 17 23" fill="none">
                                                            <mask id="mask0_7130_1642" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={0} y={0} width={17} height={23}>
                                                                  <path d="M17 0.33252H0V22.3325H17V0.33252Z" fill="white" />
                                                            </mask>
                                                            <g mask="url(#mask0_7130_1642)">
                                                                  <path d="M3.79297 16.9765L12.747 8.0235H5.87097V6.0235H16.205V16.3585H14.205V9.3935L5.20497 18.3935L3.79297 16.9765Z" fill="white" />
                                                            </g>
                                                      </svg>
                                                      <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width={17} height={23} viewBox="0 0 17 23" fill="none">
                                                            <mask id="mask0_7130_1642" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={0} y={0} width={17} height={23}>
                                                                  <path d="M17 0.33252H0V22.3325H17V0.33252Z" fill="white" />
                                                            </mask>
                                                            <g mask="url(#mask0_7130_1642)">
                                                                  <path d="M3.79297 16.9765L12.747 8.0235H5.87097V6.0235H16.205V16.3585H14.205V9.3935L5.20497 18.3935L3.79297 16.9765Z" fill="white" />
                                                            </g>
                                                      </svg>
                                                </a>

                                          </div>
                                    </div>
                                    <div className="store-img">
                                          <img src="/assets/image/shopify_funnle/hero.svg" className="w-100" alt="shopify group" />
                                    </div>
                              </div>
                        </div>
                        <div className="section-marquee-slider">
                              <div className="container-inner">
                                    <div className="d-flex align-items-center gap_20">
                                          <p className="marquee-title color_dark font-inter">Trusted by 200+ client across the world</p>
                                          <div className="v-border" />
                                    </div>
                              </div>
                              <div className="overflow-hidden marquee_shadow position-relative">
                                    <div className="brand_marquee" width="100%" direction="both">
                                          <div className="d-flex align-items-center gap-xl-3 gap-md-2">
                                                <img src="/assets/image/shopify_funnle/brand_logo/anand_sweets.svg" alt="Anand sweets" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/chaykra.svg" alt="chaykra" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/dr_vaidya.svg" alt="Dr Vaidya" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/hamdard.svg" alt="hamdard" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/itc.svg" alt="ITC" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/mia.svg" alt="Mia" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/nola.svg" alt="nola" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/ras.svg" alt="Ras" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/rpsg_group.svg" alt="RPSG Group" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/sparkfusion.svg" alt="Sparkfusion" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/techno_sport.svg" alt="Techno Sport" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/u-bar.svg" alt="U Bar" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/uppercase_logo.svg" alt="Uppercase" />
                                                <img src="/assets/image/shopify_funnle/brand_logo/within.svg" alt="Within" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  {/* Conversion Section */}
                  <div className="funnle_branding_padding pt-0">
                        <div className="container-inner">
                              <div className="growth-section">
                                    <div className="growth-card justify-content-between box-1">
                                          <div>
                                                <h2 className="second_title font-inter mb_10">Higher Conversion</h2>
                                                <p className="pera_text font-inter mb-0">We create websites that grab attention and make it easy for visitors to take action. Creative, effective designs that convert traffic into return customers and drive growth.</p>
                                          </div>
                                          <div>
                                                <img src="/assets/image/shopify_funnle/growth.jpg" className="w-100" alt="Growth" />
                                          </div>
                                    </div>
                                    <div className="d-flex flex-lg-column flex-sm-row flex-column gap10 grid_span">
                                          <div className="growth-card">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Custom Landing Pages</h2>
                                                      <p className="pera_text font-inter mb-0">Our designed landing pages that align with your brand’s goals, making clicks into conversions with engaging aspect and seamless design.</p>
                                                </div>
                                                <div>
                                                      <img src="/assets/image/shopify_funnle/custom-page.jpg" className="w-100" alt="custom-page" />
                                                </div>
                                          </div>
                                          <div className="growth-card">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Ecommerce SEO</h2>
                                                      <p className="pera_text font-inter mb-0">We’ll improve your brand’s visibility, drive organic traffic, and create sales with minimal effort.</p>
                                                </div>
                                                <div>
                                                      <img src="/assets/image/shopify_funnle/ecommerce-seo.jpg" className="w-100" alt="ecommerce-seo" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  {/* Stories Slider */}
                  <div className="funnle_branding_padding">
                        <div className="container-inner">
                              <div className="growth-section position-relative">
                                    <div className="growth-card justify-content-between storis_card">
                                          <div>
                                                <h2 className="third_title font-inter mb-3">Success Stories</h2>
                                                <p className="pera_text font-inter mb-0">Our work speaks for itself, but our clients say it even better.</p>
                                          </div>
                                    </div>
                                    <div className="overflow-hidden box-2 mt-sm-0 mt_10">
                                          <div className="section_detail">
                                                <div className="">
                                                      <Swiper
                                                            speed={1500}
                                                            spaceBetween={10}
                                                            autoplay={false}
                                                            navigation={{
                                                                  nextEl: ".aboutus_next",
                                                                  prevEl: ".aboutus_prev",
                                                            }}
                                                            pagination={false}
                                                            draggable={true}
                                                            loop={true}
                                                            breakpoints={{

                                                                  360: {
                                                                        slidesPerView: 1.1,
                                                                  },

                                                                  400: {
                                                                        slidesPerView: 1.4,
                                                                  },

                                                                  481: {
                                                                        slidesPerView: 1,
                                                                  },

                                                                  992: {
                                                                        slidesPerView: 2,
                                                                  },
                                                                  1920: {
                                                                        slidesPerView: 2,
                                                                  },
                                                            }}
                                                            modules={[Navigation, Pagination, Autoplay]}
                                                            className="mySwiper swiper_slide"
                                                      >
                                                            <SwiperSlide>
                                                                  <div className="growth-card justify-content-between brand_bg">
                                                                        <div className="brand_bg brand_bg2 h-100 d-flex flex-column justify-content-between">
                                                                              <div className="logo-size">
                                                                                    <img src="/assets/image/shopify_funnle/brand_logo/outerworld.png" className="w-100" alt="Outerworld" />
                                                                              </div>
                                                                              <div className="d-flex flex-column gap30">
                                                                                    <p className="pera_text font-inter mb-0">They helped us craft our brand identity and designed a website that perfectly showcases our stylish, high-quality clothing.</p>
                                                                                    <div className="v-border" />
                                                                                    <div className="d-flex align-items-center gap10">
                                                                                          <img src="/assets/image/shopify_funnle/outerworld-founder.png" alt="user" />
                                                                                          <div className="user-details">
                                                                                                <h3 className="font-inter mb-1">Niharika</h3>
                                                                                                <p className="font-inter">Founder of a Outerworld</p>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>

                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card justify-content-between">
                                                                        <div>
                                                                              <div className="d-flex align-items-center star_icon gap-1 mb_20">
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                              </div>
                                                                              <p className="pera_text font-inter mb-0">We worked on our website migration with aBox, and it was a great decision! As a leading brand in bags and cases, the process was super smooth, and sales have doubled!</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column gap30">
                                                                              <div className="d-flex flex-column gap_20">
                                                                                    <div className="totle-user d-flex align-items-center gap10">
                                                                                          <h3 className="font-inter mb-0">+40%</h3>
                                                                                          <p className="font-inter">Customer <br className="d-md-block d-none" /> retention</p>
                                                                                    </div>
                                                                                    <div className="totle-user d-flex align-items-center gap10">
                                                                                          <h3 className="font-inter mb-0">2x</h3>
                                                                                          <p className="font-inter">Conversion <br className="d-md-block d-none" /> rate</p>
                                                                                    </div>
                                                                              </div>
                                                                              <div className="v-border opacity_10"></div>
                                                                              <div className="d-flex align-items-center justify-content-between">
                                                                                    <div className="totle-user d-flex align-items-center gap-1 ">
                                                                                          <span>Shopify</span>
                                                                                          <p className="font-inter">Migration</p>
                                                                                    </div>
                                                                                    <div className="client-logo">
                                                                                          <img src="/assets/image/clients/uppercase.svg" className="w-100" alt="Uppercase" />
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </SwiperSlide>

                                                            <SwiperSlide>
                                                                  <div className="growth-card justify-content-between brand_bg brand_thenga">
                                                                        <div className="brand_bg brand_bg2 brand_thenga2 h-100 d-flex flex-column justify-content-between">
                                                                              <div className="logo-size">
                                                                                    <img src="/assets/image/shopify_funnle/brand_logo/thenga.svg" alt="Thenga" />
                                                                              </div>
                                                                              <div className="d-flex flex-column gap30">
                                                                                    <p className="pera_text font-inter mb-0">Thanks to their amazing website design and our unique brand, we’ve grown into a million-dollar business!</p>
                                                                                    <div className="v-border" />
                                                                                    <div className="d-flex align-items-center gap10">
                                                                                          <img src="/assets/image/shopify_funnle/thenga-founder.png" alt="user" />
                                                                                          <div className="user-details">
                                                                                                <h3 className="font-inter mb-1">Maria Kuriakose</h3>
                                                                                                <p className="font-inter">Founder of a Thengacoco</p>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card justify-content-between">
                                                                        <div>
                                                                              <div className="d-flex align-items-center star_icon gap-1 mb_20">
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                                    <StarIcon />
                                                                              </div>
                                                                              <p className="pera_text font-inter mb-0">Working with aBox was a game-changer for Naturali. They understood our brand and created a beautiful, functional website that reflects our values. Highly recommend aBox for elevating your brand!</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column gap30">
                                                                              <div className="d-flex flex-column gap_20">
                                                                                    <div className="totle-user d-flex align-items-center gap10">
                                                                                          <h3 className="font-inter mb-0">55%</h3>
                                                                                          <p className="font-inter">Customer <br className="d-md-block d-none" /> retention</p>
                                                                                    </div>
                                                                                    <div className="totle-user d-flex align-items-center gap10">
                                                                                          <h3 className="font-inter mb-0">2.8x</h3>
                                                                                          <p className="font-inter">Conversion <br className="d-md-block d-none" /> rate</p>
                                                                                    </div>
                                                                              </div>
                                                                              <div className="v-border opacity_10"></div>
                                                                              <div className="d-flex align-items-center justify-content-between">
                                                                                    <div className="totle-user d-flex align-items-center gap-1 ">
                                                                                          <span>SHOPiFY</span>
                                                                                          <p className="font-inter">Revamp</p>
                                                                                    </div>
                                                                                    <div className="client-logo text-end">
                                                                                          <img src="/assets/image/shopify_funnle/brand_logo/naturali.svg" alt="Naturali" />
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </SwiperSlide>

                                                            <div className="d-flex align-items-center gap-2 slider_button">
                                                                  <div className="arrow-circle d-flex align-items-center justify-content-center aboutus_prev">
                                                                        <LeftArrow />
                                                                  </div>
                                                                  <div className="arrow-circle d-flex align-items-center justify-content-center aboutus_next">
                                                                        <RightArrow />
                                                                  </div>
                                                            </div>
                                                      </Swiper>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  {/* Shopify Store Slider */}
                  <div className="container-inner">
                        <p className="text-gradient font-inter">We've delivered results!</p>
                        <h3 className="third_title mb-0 text-center font-inter">Experience <br className="d-sm-block d-none" /> Designed Shopify Stores <br className="d-sm-block d-none" /> That Drive Growth</h3>
                  </div>
                  <div className="funnle_branding_padding">
                        <div className="container-inner">
                              <div className="growth-section grid_row d-flex">
                                    <div className="overflow-hidden box-2 mt-sm-0 mt_10">
                                          <div className="section_detail">
                                                <div>
                                                      <Swiper
                                                            speed={1500}
                                                            spaceBetween={10}
                                                            autoplay={true}
                                                            pagination={false}
                                                            draggable={true}
                                                            loop={true}
                                                            breakpoints={{

                                                                  320: {
                                                                        slidesPerView: 1.1,
                                                                  },

                                                                  360: {
                                                                        slidesPerView: 1.5,
                                                                  },

                                                                  412: {
                                                                        slidesPerView: 1.9,
                                                                  },

                                                                  576: {
                                                                        slidesPerView: 2.5,
                                                                  },

                                                                  768: {
                                                                        slidesPerView: 3,
                                                                  },

                                                                  1920: {
                                                                        slidesPerView: 3,
                                                                  },
                                                            }}
                                                            modules={[Navigation, Pagination, Autoplay]}
                                                            className="mySwiper swiper_slide"
                                                      >
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/within.jpg" className="w-100 border-radius-28" alt="widthin" />
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/sparkfusion.jpg" className="w-100 border-radius-28" alt="sparkfusion" />
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/mia.jpg" className="w-100 border-radius-28" alt="Mia" />
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/within.jpg" className="w-100 border-radius-28" alt="widthin" />
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/sparkfusion.jpg" className="w-100 border-radius-28" alt="sparkfusion" />
                                                                  </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                  <div className="growth-card bg-transparent p-0">
                                                                        <img src="/assets/image/shopify_funnle/mia.jpg" className="w-100 border-radius-28" alt="Mia" />
                                                                  </div>
                                                            </SwiperSlide>
                                                      </Swiper>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <div className="container-inner">
                        <div className="div-block-200">
                              <p className="pera_type font-inter mb-0">We’ve worked with brands across various industries, understanding what each needs. Our focus is on designing and developing websites that drive sales and build the strong brand presence your business truly deserves.</p>
                        </div>
                  </div>

                  <div className="funnle_branding_padding">
                        <div className="container-inner">
                              <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                          <img src="/assets/image/shopify_funnle/box-frame.png" className="w-100" alt="frame" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  {/* conversion Section */}
                  <div className="container-inner">
                        <div className="div-block-200">
                              <h2 className="third_title mb-0 text-center font-inter pb_20">Powerful ways <br /> to get best conversion</h2>
                              <p className="pera_text font-inter text-center mb-0">We’ve carefully filtered the best strategies and will focus on the key elements <br className="d-sm-block d-none" /> that will deliver the highest possible conversions for your brand.</p>
                        </div>
                  </div>

                  <div className="funnle_branding_padding">
                        <div className="container-inner">
                              <div className="growth-section grid_none">
                                    <div className="grid-1 mb_10 gap10">
                                          <div className="growth-card justify-content-between">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Store design</h2>
                                                      <p className="pera_text font-inter mb-0">We design stores that are simple, attractive, and easy to navigate, making shopping enjoyable and encouraging customers to buy more</p>
                                                </div>
                                                <div className="position-relative">
                                                      <img src="/assets/image/shopify_funnle/store.jpg" className="w-100" alt="Store" />
                                                </div>
                                          </div>
                                          <div className="growth-card box-2">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Shopify migration</h2>
                                                      <p className="pera_text font-inter mb-0">Within seconds, see your ideas turn into reality, <br /> making it faster, more efficient, and ready to grow.</p>
                                                </div>
                                                <div>
                                                      <img src="/assets/image/shopify_funnle/shopify-migration.jpg" className="w-100" alt="Shopify Migration" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="grid-2 mb_10 gap10">
                                          <div className="growth-card">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Support & Maintenance</h2>
                                                      <p className="pera_text font-inter mb-0">Our reliable support and maintenance services to keep your store running smoothly with regular updates and quick fixes.</p>
                                                </div>
                                                <div>
                                                      <img src="/assets/image/shopify_funnle/maintenance.jpg" className="w-100" alt="Shopify Maintenance" />
                                                </div>
                                          </div>
                                          <div className="growth-card">
                                                <div>
                                                      <h2 className="second_title font-inter mb_10">Reduce App Dependancy</h2>
                                                      <p className="pera_text font-inter mb-0">We’ll reduce unnecessary apps, minimize third-party dependencies, and optimize your store, saving costs and improving performance.</p>
                                                </div>
                                                <div>
                                                      <img src="/assets/image/shopify_funnle/app-dependancy.jpg" className="w-100" alt="App Dependancy" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="growth-card d-flex flex-lg-row flex-column">
                                          <div className="box-4">
                                                <h2 className="second_title font-inter mb_10">Conversion Rate Optimization</h2>
                                                <p className="pera_text font-inter mb-0">To get the most out of your visitors, your site needs optimization. We’re experts at CRO, using a proven checklist to boost sales, give your brand the spotlight, sales and obviously it build trust.</p>
                                          </div>
                                          <div>
                                                <img src="/assets/image/shopify_funnle/cro.jpg" className="w-100" alt="Conversion Rate Optimization" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  {/* Footer  */}
                  <div className="funnle_branding_padding">
                        <div className="container-inner">
                              <div className="footer-grid gap_80 side_space">
                                    <div className="shopi-footer-title">
                                          <div className="d-flex flex-column gap_20">
                                                <p className="text-gradient pb-0 text-start font-inter">Contact Us Now!!</p>
                                                <h2 className="third_title mb-0 font-inter">We're Here to Help Your Online Store Grow!</h2>
                                                <p className="pera_text font-inter mb-0">Have questions about how aBox can improve your online shop? We're here to assist you—reach out anytime!</p>
                                          </div>
                                          <div className="v-border my_40 d-md-block d-none"></div>
                                          <div className="d-none d-md-block">
                                                <div className="d-flex flex-sm-row flex-column justify-content-lg-start justify-content-between gap100">
                                                      <div className="d-flex flex-column">
                                                            <p className="v-address font-inter">Contact Information</p>
                                                            <a href="tel:+ 91 70462 16489" className="pera_content font_medium opacity_7 font-inter mb-1">Phone: +91 70462 16489</a>
                                                            <a href="mailto:hi@abox.agency" className="pera_content font_medium opacity_7 font-inter">Email: hi@abox.agency</a>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="growth-section grid_none">
                                          <div className="growth-card gap_20">
                                                <h2 className="second_title font-inter mb-0">Send us a message</h2>
                                                <div className="d-flex flex-column gap-3">
                                                      <div className="position-relative">
                                                            <label className="form_label font-inter">Name</label>
                                                            <input type="text" name="name" value={inputs.name} onChange={handleInputChange} className="form-control user_details get" placeholder="Your Name" />
                                                            {validations.name && <div className="validations_text form-tooltip">Please enter name</div>}
                                                            {/* <input type="text" name="companyName" value={inputs.companyName} onChange={handleInputChange} className="form-control user_details get" placeholder="Company Name" />
                                                      {validations.companyName && <div className="validations_text">Please enter company name</div>} */}
                                                      </div>
                                                      {/* <div className="position-relative">
                                                            <input autoCapitalize="false" type="text" name="website" value={inputs.website} onChange={handleInputChange} className="form-control user_details get" placeholder="Website" />
                                                            {validations.website && <div className="validations_text">Please enter webiste</div>}
                                                      </div> */}
                                                      <div className="position-relative">
                                                            <label className="form_label font-inter">Email</label>
                                                            <input type="email" name="email" value={inputs.email} onChange={handleInputChange} className="form-control user_details get" placeholder="Email Address" />
                                                            {validations.email && <div className="validations_text form-tooltip">{inputs.email ? 'Invalid email' : 'Please enter email address'}</div>}
                                                      </div>
                                                      <div className="position-relative">
                                                            <label className="form_label font-inter">Message</label>
                                                            <textarea name="aboutProject" value={inputs.aboutProject} onChange={handleInputChange} className="form-control user_details get" placeholder="Leave us a message"></textarea>
                                                            {validations.aboutProject && <div className="validations_text form-tooltip">Please enter message</div>}
                                                      </div>
                                                      <div>
                                                            <button onClick={sendData} type="button" className="btn submit_button w-100">{loading ? 'Loading...' : 'Send message'}</button>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="v-border my_40 d-md-none d-block"></div>
                                    <div className="d-block d-md-none">
                                          <div className="d-flex flex-sm-row flex-column justify-content-lg-start justify-content-between gap100">
                                                <div className="d-flex flex-column">
                                                      <p className="v-address font-inter">Contact Information</p>
                                                      <a href="tel:+ 91 70462 16489" className="pera_content font_medium opacity_7 font-inter mb-1">Phone: +91 70462 16489</a>
                                                      <a href="mailto:hi@abox.agency" className="pera_content font_medium opacity_7 font-inter">Email: hi@abox.agency</a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  {/* <div className="shopify_label">
                        <p className="font-inter">© 2024 ALL RIGHTS RESERVED</p>
                  </div> */}

                  <Footer className={"footer-space"} />
                  <div className="mobile_btn align-items-center">
                        <a href="https://vz3wn4o7lst.typeform.com/to/eHY3sxf9" target="_blank" className="web-button font-inter">Audit my website <Arrow /></a>
                        <a href="https://wa.link/o7n48g" target="_blank" className="web-button chat-btn font-inter"><ChatIcon /> Quick chat</a>
                  </div>

            </>
      );
}