import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioNaturare() {

    return (
        <>
            <Helmet>
                <title>Naturare’s Portfolio Showcase | Nurturing Health with Nature | aBox Agency</title>
                <meta name="title" content="Naturare’s Portfolio Showcase | Nurturing Health with Nature | aBox Agency" />
                <meta name="description" content="Step into the world of Naturare, where health and nature intertwine. Discover aBox Agency's digital artistry in creating an online haven that celebrates and promotes a healthier lifestyle through Naturare's innovative health products. Visit The website and portfolio to experience the perfect blend of nature's best and digital excellence." />
                <meta name="keywords" content="Naturare, aBox Agency, Web Design, Web Development, Shopify, E-commerce, User Experience, Branding, 3D Rendering, UI/UX Design, SEO, Health Products, Dietary Supplements, Online Shopping, Startup Success, Digital Innovation, Creative Strategy, Client Portfolio, Success Stories, Digital Transformation, Wellness Journey, Interactive Design, Tech Artisans, Bespoke Solutions, Project Management, Multimedia Design, Front-End, Back-End, Brand Identity" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/naturare/" />
            </Helmet>

            <Header />

            <div className="page_bg naturare_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name paper_text_bg text_light">Naturare</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1710325841.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name paper_text_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">Naturare <br /> <span>Crafting Health's Digital presence with Minds and Move</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Witness the seamless fusion of creativity and functionality in Naturare's online presence, meticulously crafted by aBox. Our bespoke website design and development showcase the essence of this health and diet startup, empowering its journey from inception to digital success. Explore aBox's commitment to precision and innovation in transforming ideas into captivating online experiences.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://naturare.in/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>User Research</li>
                                                <li>Project Management</li>
                                                <li>Brand Design</li>
                                                <li>3D Render</li>
                                                <li>Label Design</li>
                                                <li>Interface Design</li>
                                                <li>Interaction Design</li>
                                                <li>Design System</li>
                                                <li>Multimedia Design</li>
                                                <li>Front-End Development</li>
                                                <li>Back-End Development</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Brand Identity</li>
                                                <li>Full Shopify Development</li>
                                                <li>3D Product Render</li>
                                                <li>UIUX</li>
                                                <li>SEO</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/naturare/naturare1.webp" className="w-100" alt="Naturare" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Building a Solid Base for Naturare's Flourishing Brand</h2>
                                    <p className="services_pera text_light font_regular mb-0">Rooted in the ambition of a visionary entrepreneur, this health and diet startup is set to make waves. Our tech artisans meticulously shaped a seamless online shopping realm, customizing every facet to embody the brand's uniqueness. Experience the blend of innovation and purpose, navigating through an intuitive interface that showcases Naturare's diverse and delightful range of health products.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80">
                                <img src="/assets/image/portfolios/naturare/naturare2.webp" className="w-100" alt="Naturare" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Empowering Wellness with Nature's Touch</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">Naturare, a passionate venture by a visionary founder, is on a mission to revolutionize health and diet. Discover a unique range of products crafted to make a significant impact. Dive into the world of <a href="https://naturare.in/" className="text-white portfolio_a" target="_blank">www.naturare.in</a>, where a girl's dream converges with the essence of well-being, offering a distinct fusion of health and dietary excellence.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <img src="/assets/image/portfolios/naturare/naturare3.webp" className="w-100" alt="Naturare" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Working with the aBox agency for our company, Naturare, was an exceptional experience. Their web design and development skills were top-notch, resulting in a visually appealing and user-friendly website that perfectly showcased our health management products. They are professional, efficient, and attentive to our needs throughout the process, incorporating our ideas seamlessly. I highly recommend the web agency for anyone seeking expert web design, development, and 3D rendering services. They are true professionals and a pleasure to work with.</p>
                                    <h3 className="text_shiska padd_s">Vinotha</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Naturare</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg fastfry_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/fast-n-fry" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}