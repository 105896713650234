import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelKutum() {

      return (
            <>
                  <Helmet>
                        <title>3D Product Rendering for Kutum Ayurveda | aBox Agency</title>
                        <meta name="title" content="3D Product Rendering for Kutum Ayurveda | aBox Agency" />
                        <meta name="description" content="Abox Agency created realistic 3D renders and product visualisations for Kutum Ayurveda’s organic products, guiding them from concept to execution to elevate their brand presence." />
                        <meta name="keywords" content="Abox Agency, 3D rendering, Ayurvedic products, organic beauty, wellness products, 3D product modeling, texture design, product visualization, health and beauty, eCommerce visuals, digital marketing for startups, Ayurvedic skincare, organic health products, natural products, 3D product design, wellness brand, beauty product modeling, packaging design, 3D textures, Ayurvedic wellness, 3D product presentation, eCommerce marketing, Kutum Ayurveda, organic skincare visuals, holistic beauty solutions, 3D rendering agency, 3D product presentation, Ayurvedic product design, consultation services, natural beauty products" />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/kutum-ayurveda/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Kutum Ayurveda</span> <br /> <span className="text_black">Elevating Ayurvedic <br /> Products with 3D</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/kutum/kutum1.jpg"
                                    alt="Kutum" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Kutum Ayurveda, a startup brand offering organic Ayurvedic products for health, beauty, and nutrition, approached us for 3D rendering and product modeling services. We created realistic, high-quality visuals for their product range, ensuring each product’s design aligned with their organic values and Ayurvedic principles.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Product Visualization</li>
                                                                  <li>Texture Design</li>
                                                                  <li>3D Consultation & Guidance</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Organic Wellness Products</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="mt_30">
                                          <img src="/assets/image/label/kutum/kutum2.jpg" className="w-100" alt="Kutum" />
                                    </div>

                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/kutum/kutum3.jpg" className="w-100" alt="Kutum" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/kutum/kutum4.jpg" className="w-100" alt="Kutum" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We delivered detailed 3D renders, modelling, and texture design for their product lineup. Our team also provided consultation and guidance from the start to ensure the final visuals matched Kutum Ayurveda’s brand identity and helped them stand out in the competitive wellness market. The high-quality renderings boosted their online presence and brand appeal.</p>
                                    </div>
                                    <div className="mb-4">
                                          <img src="/assets/image/label/kutum/kutum5.jpg" className="w-100" alt="Kutum" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}