import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelMaisonJarin() {

      return (
            <>
                  <Helmet>
                        <title>3D Design & Modeling for Tea Brand | aBox Agency</title>
                        <meta name="title" content="3D Design & Modeling for Tea Brand | aBox Agency" />
                        <meta name="description" content="Abox Agency created detailed 3D renders and packaging models for a handcrafted tea brand, showcasing their boxes and sachets with lifelike textures and vibrant designs." />
                        <meta name="keywords" content="3D rendering, product modelling, packaging design, tea packaging, sachet design, box design, 3Dvisualisationn, texturing, product presentation, Maison De Jarin, handcrafted blends, custom packaging, tea brand, product mockups, branding, eCommerce packaging, product design, creative packaging, realistic 3D models, product packaging design, packaging mockup, tea branding, sustainable packaging, luxury tea, innovative tea packaging, 3D product design, premium tea, packaging texture, realistic render, 3D modelling, creative design, product showcase, eco-friendly packaging, tea lovers, product visualisation, branding design." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/mison-jarin/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Maison De Jarin</span> <br /> <span className="text_black">Brewing Perfection: <br /> 3D Tea Packaging</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/maisonjarin/maisonjarin1.jpg"
                                    alt="Maison Jarin" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">This brand specialises in handcrafted tea blends that cater to adventurous souls seeking unique flavours. We collaborated to create detailed 3D designs, renders, and models for their packaging, including boxes and sachets. Our goal was to elevate the visual appeal of their products while capturing the essence of their creative and adventurous spirit. The brand needed high-quality, visually striking 3D models and renders for their tea packaging to enhance their online presence and resonate with their target audience. The challenge was to bring out the uniqueness of their products while reflecting their passion for flavours and moments of discovery.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Design</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Texturing</li>
                                                                  <li>3D Rendering</li>
                                                                  <li>Packaging Visualization</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Beverages & Tea</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin2.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin3.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin4.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin5.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin6.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="mt_30">
                                          <img src="/assets/image/label/maisonjarin/maisonjarin7.jpg" className="w-100" alt="Maison Jarin" />
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We created detailed 3D designs and models for the brand’s boxes and sachets, focusing on texture, presentation, and lifelike visuals. By applying realistic texturing and product placement, we ensured the final renders brought the brand’s creative and adventurous identity to life. Our work helped highlight the uniqueness of each tea blend through photorealistic visuals. Our work contributed to the brand’s enhanced digital presence, offering visually appealing packaging renders that captured the adventurous spirit of their handcrafted blends. The 3D designs played a crucial role in making the products stand out, improving customer engagement and appeal.</p>
                                    </div>
                                    <div className="row gap-md-0 gap-4">
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin8.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin9.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-4">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin10.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4 mb-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin11.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/maisonjarin/maisonjarin12.jpg" className="w-100" alt="Maison Jarin" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}