import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelAcnex() {

    return (
        <>
            <Helmet>
                <title>3D Rendering and Product Modeling for Acne-X Skincare | aBox Agency</title>
                <meta name="title" content="3D Rendering and Product Modeling for Acne-X Skincare | aBox Agency" />
                <meta name="description" content="aBox Agency created detailed 3D renderings and product modelling for Acne-X's skincare line. We showcased their products with lifelike textures and realistic presentations to enhance their online store and brand image." />
                <meta name="keywords" content="aBox Agency, 3D rendering, product modelling, skincare products, acne solutions, realistic product visuals, 3D product visualisation, product textures, eCommerce visuals, premium skincare, Acne-X, realistic 3D mockups, skincare packaging, beauty product rendering, high-quality product images, skincare marketing materials, 3D modelling for skincare, realistic product rendering, beauty eCommerce, product visualisation for skincare, acne skincare visuals" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/acnex-topical/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                <h1 className="services_title ras_title mb-0"><span>Acne-X</span> <br /> <span className="text_black">Routine Acne-X  <br /> 3D Rendering</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/acnex/acnex1.jpg"
                        alt="Acnex" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Acne-X is a skincare brand dedicated to effective acne solutions and skincare routines. For this project, we created 3D product renderings, modelling, and realistic presentations for their three main skincare products. The goal was to showcase the products in a way that highlighted their quality and effectiveness.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>3D Rendering</li>
                                            <li>Product Modeling</li>
                                            <li>Product Visualization</li>
                                            <li>Realistic Texturing and Presentation</li>
                                            <li>Mockups</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Skincare & Beauty</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex2.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex3.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex4.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/acnex/acnex5.jpg" className="w-100" alt="Acnex" />
                        </div>
                        <div className="content_space">
                            <p className="services_content text_black">We worked on 3D product rendering and modelling to bring Acne-X's skincare line to life. Using realistic textures, we focused on showing how the products look in real life, ensuring the visuals were as lifelike and detailed as possible. Our aim was to make the products stand out with a premium, realistic presentation that would attract customers online.</p>
                        </div>
                        <div className="row mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex6.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex7.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/acnex/acnex8.jpg" className="w-100" alt="Acnex" />
                        </div>
                        <div className="content_space">
                            <p className="services_content text_black">These 3D renderings and models helped Acne-X enhance their online presence, giving the products a polished and professional look. The final visuals not only highlighted the product design but also improved customer engagement, making the brand stand out in the competitive skincare market.</p>
                        </div>
                        <div className="row mb-5 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex9.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/acnex/acnex10.jpg" className="w-100" alt="Acnex" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}