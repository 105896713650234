import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../component/Header'
import Footer from '../component/Footer'
// import Marquee from 'react-fast-marquee'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from 'axios'
import CROTick from '../component/icons/CROTick.js'
import { useNavigate } from 'react-router';

function ConversionRateOptimization() {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' })
    const [validations, setValidations] = useState({ name: false, email: false, message: false })
    const [open, setOpen] = useState(false)
    const talkCardRefs = useRef([]);

    const navigate = useNavigate();

    const handleChange = async (e) => {
        const { name, value } = e.target
        setValidations({ ...validations, [name]: false })
        setFormData({ ...formData, [name]: value })
    }

    const onSubmit = async (e) => {
        if (!formData['name']) {
            setValidations({ ...validations, name: true })
            return
        }
        if (!formData['email'] || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email))) {
            setValidations({ ...validations, email: true })
            return
        }
        if (!formData['message']) {
            setValidations({ ...validations, message: true })
            return
        }

        navigate('/thank-you')

        try {
            await axios.post('https://formsubmit.co/ajax/65d554b105dcb5e0977b45ca2c6a2df7',
                {
                    _subject: 'Inquiry from CRO Page Form',
                    _template: 'table',
                    name: formData.name,
                    email: formData.email,
                    message: formData.message
                })
            setOpen(true)
            setFormData({ name: '', email: '', message: '' })
        } catch (error) {
            console.log(error)
        }
    }

    gsap.registerPlugin(ScrollTrigger);

    const directionRef = useRef(1);
    const tl = useRef(null);

    const animateMarquee = () => {
        const duration = 20;
        const marquees = document.querySelectorAll(".marquee");

        tl.current = gsap.timeline({
            repeat: -1,
            yoyo: false,
            onReverseComplete() {
                this.totalTime(this.rawTime() + this.duration() * 15);
            }
        });

        marquees.forEach(marquee => {
            tl.current.to(marquee.querySelectorAll("p"), {
                xPercent: marquee.dataset.reversed === "true" ? -100 : 100,
                repeat: 0,
                ease: "linear",
                duration: duration
            }, "<");
        });

        const scroll = ScrollTrigger.create({
            onUpdate(self) {
                if (self.direction !== directionRef.current) {
                    directionRef.current *= -1;
                }

                const speedMultiplier = 0.2;
                tl.current.timeScale(speedMultiplier * duration * self.getVelocity() / 500);
                gsap.to(tl.current, { timeScale: directionRef.current });
            }
        });

        return () => scroll.kill();
    };

    useEffect(() => {
        animateMarquee();
    }, []);

    // Card Hover
    useEffect(() => {
        const handleMouseEnter = (event, index) => {
            const talkCard = talkCardRefs.current[index];
            talkCard.classList.add('animate');
            const { offsetX, offsetY } = event;
            talkCard.querySelector('.round').style.cssText = `top:${offsetY}px;left:${offsetX}px;width:1px;height:1px;`;
        };

        const handleMouseLeave = (index) => {
            const talkCard = talkCardRefs.current[index];
            talkCard.classList.remove('animate');
        };

        talkCardRefs.current.forEach((talkCard, index) => {
            talkCard.addEventListener('mouseenter', (event) => handleMouseEnter(event, index));
            talkCard.addEventListener('mouseleave', () => handleMouseLeave(index));
        });

        // Cleanup event listeners on component unmount
        return () => {
            talkCardRefs.current.forEach((talkCard, index) => {
                talkCard.removeEventListener('mouseenter', (event) => handleMouseEnter(event, index));
                talkCard.removeEventListener('mouseleave', () => handleMouseLeave(index));
            });
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Boost Website Conversion Rate | Conversion Rate Optimization Services | aBox Agency</title>
                <meta name="title" content="Boost Website Conversion Rate | Conversion Rate Optimization Services | aBox Agency" />
                <meta name="description" content="Enhance your website's performance with our Conversion Rate Optimization (CRO) services. Our team offers data-driven analysis, design solutions, and A/B testing to maximize your sales and revenue. Let's optimize your conversion rates today!" />
                <meta name="keywords" content="aBox Agency, aBox, Web agency, CRO, Shopify, CRO Services, Conversion Rate Optimization, Analysis for CRO, Website Design for CRO, A/B Testing for CRO, Revenue Boosting Strategies, Design for Optimization, Competitor Analysis for CRO, Conversion-C Design, User Behavior Analysis, Heuristic Analysis, Segmentation Analysis, Landing Page Design, Branding Strategies, Form Optimization, Information Architecture, Usability Analysis, Graphic Design for CRO, Ecommerce Optimization, Website Conversion Strategies, Conversion Rate, CRO Specialists, Optimization Solutions, Data-CRO, Conversion-Centric Copywriting" />
                <link rel="canonical" href="https://www.abox.agency/conversion-rate-optimization/" />
            </Helmet>

            <Header />

            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 cro_padding">
                                <h1 className="service_heading cro_title">Conversion <br className='d-lg-block d-none' />
                                    Rate Optimizer</h1>
                                <p className="cro_p">Start with a gap-analysis for your site.</p>
                                <div className="logo_btn mt-4">
                                    <a href="/contact" className="btn submit_btn cro_btn btn_lest">Let’s chat
                                        <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" />
                                        </svg>
                                        <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                            <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/cro/cro.webp" alt="cro" className="w-100" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="boll-set-box">
                <div className='container-fluid'>
                    <div>
                        <h2 className="header-text mb-0">Serving hundreds of <br /> websites across</h2>
                    </div>
                    <div className="tricks-canvas"></div>
                    <div className="tricks-elements"></div>
                </div>
            </section>

            <div className='section_detail cro_section_padding'>
                <div className='container'>
                    <div className='row design_card'>
                        <div className='col-12 d-flex flex-column justify-content-center align-items-center text-center'>
                            <p className='cro_design_p mb-3'>CRO - Design</p>
                            <h2 className='cro_design_h2'>What bring you here?</h2>
                        </div>
                        <div className='col-lg-6'>
                            <div className="talk_card talk_animation" ref={(el) => (talkCardRefs.current[0] = el)}>
                                <h3 className='talk_h3'>I want to improve my site’s <span className='green_light'>conversion rate</span></h3>
                                <p className='talk_p'>Start with a gap-analysis for your site. Understand where and why users leave your website and how we can fix that.</p>
                                <div className="logo_btn mt-0">
                                    <a href="" className="btn submit_btn cro_btn talk_btn">Let’s chat CRO</a>
                                </div>
                                <span className='round'></span>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="talk_card talk_animation" ref={(el) => (talkCardRefs.current[1] = el)}>
                                <h3 className='talk_h3'>I want to discuss a site <span className='green_light'>design</span> project</h3>
                                <p className='talk_p'>Get your store right from the get-go. Make your website’s design conversion-centric & gain a competitor edge.</p>
                                <div className="logo_btn mt-0">
                                    <a href="" className="btn submit_btn cro_btn talk_btn">Let’s chat design</a>
                                </div>
                                <span className='round round_two'></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='section_detail cro_section_padding pt-0'>
                <div className='container-fluid'>
                    <div className='marquee marquee_slide' data-reversed="false">
                        <p>We’re &nbsp;<span>rebels</span> &nbsp; who like to think outside &nbsp; <span>the box.</span></p>
                        <p>We’re &nbsp;<span>rebels</span> &nbsp; who like to think outside &nbsp; <span>the box.</span></p>
                        <p>We’re &nbsp;<span>rebels</span> &nbsp; who like to think outside &nbsp; <span>the box.</span></p>
                        <p>We’re &nbsp;<span>rebels</span> &nbsp; who like to think outside &nbsp; <span>the box.</span></p>
                    </div>
                </div>
            </div>

            <div className='section_detail team_detail_section position-relative'>
                <div className='container'>
                    <div className='team_card'>
                        <div className='row team_space'>
                            <div className='col-lg-6 d-flex align-items-end gap-3'>
                                <h2 className='cro_h2 dark_green'>We are your <br /> in-house team</h2>
                                <img src='/assets/image/cro/arrow.svg' className='cro_img' alt='arrow' />
                            </div>
                        </div>
                        <div className='cro_card cro_card_one'>
                            <div className='row justify-content-between h-100'>
                                <div className='col-lg-6 col-12 d-flex flex-column justify-content-between'>
                                    <div>
                                        <p className='card_no'>One</p>
                                    </div>
                                    <div className='team_card_title'>
                                        <h3 className='card_title margin_30'>We boost your sales & revenue</h3>
                                        <p className='talk_p mb-0 text_cream'>Take your e-commerce sales to the next level. Find gaps using data and solve them with design solutions.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <img src='/assets/image/cro/card1.svg' alt='icon' className='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className='cro_card crd_yellow cro_card_two'>
                            <div className='row justify-content-between h-100'>
                                <div className='col-lg-6 col-12 d-flex flex-column justify-content-between h-100'>
                                    <div>
                                        <p className='card_no card_two'>Two</p>
                                    </div>
                                    <div className='team_card_title'>
                                        <h3 className='card_title dark_green margin_30'>We Complete your design tasks</h3>
                                        <p className='talk_p mb-0 dark_green'>Send us your design tasks - landing pages, banners, collection pages. We'll handle them one by one. Mosttasks done in 2-3 biz days.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5 text-md-start text-center'>
                                    <img src='/assets/image/cro/card2.svg' alt='icon' className='card_img' />
                                </div>
                            </div>
                        </div>
                        <div className='cro_card card_light_green'>
                            <div className='row justify-content-between h-100'>
                                <div className='col-lg-6 col-12 d-flex flex-column justify-content-between h-100'>
                                    <div>
                                        <p className='card_no card_two'>Three</p>
                                    </div>
                                    <div className='team_card_title'>
                                        <h3 className='card_title dark_green margin_30'>We are complete solution</h3>
                                        <p className='talk_p mb-0 dark_green'>We do everything - analysis, design, content (yes!), a/b testing. We own the results and only get in touch with you when we need approvals.</p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <img src='/assets/image/cro/card3.svg' alt='icon' className='card_img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='section_detail team_detail_section'>
                <div className='container'>
                    <ul className='cro_list'>
                        <li className='active'>Services</li>
                        <li>Data Analysis<span>(2)</span></li>
                        <li>Landing Page Design<span>(2)</span></li>
                        <li>Segmentation Analysis<span>(2)</span></li>
                        <li>CRO Strategy<span>(2)</span></li>
                        <li>Competitor Analysis<span>(2)</span></li>
                        <li>UX or Web Design<span>(2)</span></li>
                        <li>User Behavior Analysis<span>(2)</span></li>
                        <li>A/B Testing<span>(2)</span></li>
                        <li>Branding<span>(2)</span></li>
                        <li>Form Optimization<span>(2)</span></li>
                        <li>Heuristic Analysis<span>(2)</span></li>
                        <li>Information  Architecture<span>(2)</span></li>
                        <li>Website Usability Analysis<span>(2)</span></li>
                        <li>Graphic Design<span>(2)</span></li>
                        <li>And More.</li>
                    </ul>
                </div>
            </div>

            <div className='section_detail team_detail_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='cro_testing'>
                                <div className='margin_30'>
                                    <div className='d-md-flex justify-content-between'>
                                        <h3 className='talk_h3'>CRO & A/B Testing</h3>
                                        <a href="/contact" className="btn submit_btn testing_btn">Let’s chat
                                            <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#004737" />
                                            </svg>
                                            <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#004737" />
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='talk_p mb-0'>Instant access to a team of CRO specialists, UX designers, conversion-centric copy writers and developers. Work with CRO specialists with 10+ years of expertise.</p>
                                </div>
                                <div>
                                    <p className='included_title dark_green'>WHAT’S INCLUDED</p>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-6'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Data & heuristic analysis
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Competitor analysis
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    A/B testing plan - 3 months
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-md-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Development of experiments
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Monitoring experiments
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Reporting performance
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-lg-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    User behavior analysis
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Monitoring experiments
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Design & content for experiments
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-lg-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    A/B testing set-up
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Monitoring experiments
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    4 experiments per month
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='cro_testing mt-md-5 mt-4'>
                                <div className='margin_30'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 className='talk_h3'>UX Design</h3>
                                        <a href="/contact" className="btn submit_btn testing_btn">Let’s chat
                                            <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#004737" />
                                            </svg>
                                            <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#004737" />
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='talk_p mb-0'>Instant access to a team of seasoned UX and graphic designers. Get your tasks done fast and with high quality.</p>
                                </div>
                                <div>
                                    <p className='included_title dark_green'>WHAT’S INCLUDED</p>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-6'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Website design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Banner design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Homepage design
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-md-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Product page design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Landing page design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Developer hand-off
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-lg-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Cart page design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Conversion-centric design
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Brand guidelines
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-3 col-md-6 mt-lg-0 mt-2'>
                                            <ul className='included_list'>
                                                <li>
                                                    <CROTick />
                                                    Prototyping & user testing
                                                </li>
                                                <li>
                                                    <CROTick />
                                                    Monitoring experiments
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='section_detail section_working'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 col-sm-10'>
                            <h2 className='work_title'>
                                You’ll love working with us
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='working_slider'>
                    <Swiper
                        speed={2000}
                        spaceBetween={10}
                        autoplay={false}
                        pagination={false}
                        draggable={true}
                        loop={false}
                        breakpoints={{
                            360: {
                                slidesPerView: 1.1,
                            },
                            390: {
                                slidesPerView: 1.2,
                            },
                            428: {
                                slidesPerView: 1.2,
                            },
                            600: {
                                slidesPerView: 1.5,
                            },
                            768: {
                                slidesPerView: 1.9,
                            },
                            991: {
                                slidesPerView: 2.4,
                            },
                            1024: {
                                slidesPerView: 2.5,
                            },
                            1080: {
                                slidesPerView: 2.6,
                            },
                            1200: {
                                slidesPerView: 2.9,
                            },
                            1260: {
                                slidesPerView: 3.1,
                            },
                            1300: {
                                slidesPerView: 3.2,
                            },
                            1400: {
                                slidesPerView: 3.3,
                            },
                            1500: {
                                slidesPerView: 3.4,
                            },
                            1600: {
                                slidesPerView: 3.6,
                            },
                            1700: {
                                slidesPerView: 3.8,
                            },
                            1800: {
                                slidesPerView: 4.2,
                            },
                            1900: {
                                slidesPerView: 4.5,
                            },
                        }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper slider_working"
                    >
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon1.svg' alt='icon' />
                                <h3 className='working_card_title'>Fixed Price</h3>
                                <p className='working_p text_cream'>We offer transparent, fixed pricing, ensuring no hidden costs or unexpected surprises.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon2.svg' alt='icon' />
                                <h3 className='working_card_title'>We Understand Your Business</h3>
                                <p className='working_p text_cream'>We delve deep into understanding your business, tailoring strategies to meet your unique needs.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon3.svg' alt='icon' />
                                <h3 className='working_card_title'>Fast Turnaround</h3>
                                <p className='working_p text_cream'>We pride ourselves on our swift service delivery, maintaining quality in every project.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon4.svg' alt='icon' />
                                <h3 className='working_card_title'>Professional Experience</h3>
                                <p className='working_p text_cream'>Our team comprises seasoned professionals, bringing a wealth of industry experience to the table.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon5.svg' alt='icon' />
                                <h3 className='working_card_title'>Clear Communication</h3>
                                <p className='working_p text_cream'>We prioritize open and clear communication, keeping you informed at every step.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='working_card'>
                                <img src='/assets/image/cro/icons/slide_icon6.svg' alt='icon' />
                                <h3 className='working_card_title'>Data and Research Driven</h3>
                                <p className='working_p text_cream'>Our strategies are data-driven and research-backed, ensuring effective and measurable results.</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="section_detail team_detail_section letstalk_section bg-white">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 mb-lg-0 mb-4">
                            <h2 className="testimonial_title dark_green mb-3 line_80">Book a 1:1 <br className="d-lg-block d-none" /><span className="green_light">Consultation</span></h2>
                            <p className='working_p dark_green'>Talk to our team to understand what is the best way to optimize your conversion rate or design your website. Lets chat.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-2 position-relative">
                                        <input
                                            type="text"
                                            className="form-control cro_form"
                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            value={formData.name}
                                            name='name'
                                        />
                                        {validations.name && <div className="validations_text cro_validation">Please enter name</div>}
                                    </div>
                                </div>
                                <div className="col-lg-6 ps-lg-0">
                                    <div className="mb-2 position-relative">
                                        <input
                                            type="email"
                                            className="form-control cro_form"
                                            aria-describedby="emailHelp"
                                            placeholder="Email address"
                                            onChange={handleChange}
                                            value={formData.email}
                                            name='email'
                                        />
                                        {validations.email && <div className="validations_text cro_validation">{formData.email ? 'Please enter correct Email' : 'Please enter Email'}</div>}
                                    </div>
                                </div>
                                <div className="col-12 contact_detail">
                                    <div className="mb-4 position-relative">
                                        <textarea
                                            className="form-control cro_form h-100 border_20 ps-4"
                                            rows={6}
                                            defaultValue={""}
                                            placeholder="Write your message here"
                                            value={formData.message}
                                            name='message'
                                            onChange={handleChange}
                                        ></textarea>
                                        {validations.message && <div className="validations_text cro_validation">Please enter message</div>}
                                    </div>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="dark_green font_medium">Hate Contact form?</p>
                                        <a className="mail_a dark_green font_bold" href="mailto:hi@abox.agency" target="_blank">hi@abox.agency</a>
                                    </div>
                                    <div className="d-inline-block">
                                        <button onClick={() => onSubmit()} type="submit" className="btn submit_btn btn_green">Submit
                                            <svg className="ms-2 submit_arrow" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#56F09F" />
                                            </svg>
                                            <svg className="ms-2 submit_hover" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                                                <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#56F09F" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='section_detail section_lest_talk'>
                <div className='container'>
                    <a href='/contact'>
                        <div className='talking'>
                            <p className='talk_p mb-0 font_medium line_30'>You’ll be talking directly to our <span className='text-decoration-underline'>founder.</span></p>
                        </div>
                        <div className='position-relative'>
                            <h2 className='lest_talk_title'>Let’s talk...</h2>
                            <img src='/assets/image/cro/arrow_right.svg' className='talk_icon' alt='icon' />
                        </div>
                    </a>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ConversionRateOptimization