import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelAnthi() {

    return (
        <>
            <Helmet>
                <title>3D Rendering & Product Modeling for Hair Serum | aBox Agency</title>
                <meta name="title" content="3D Rendering & Product Modeling for Hair Serum | aBox Agency" />
                <meta name="description" content="Abox Agency created realistic 3D renderings and product mockups for Anthi's thinning hair serum bottle, showcasing its natural, restorative ingredients and premium design." />
                <meta name="keywords" content="Abox Agency, 3D rendering, product modeling, thinning hair serum, hair care, beauty product visualization, realistic mockups, 3D product design, serum bottle design, premium hair care, natural hair solutions, eCommerce product visuals, hair serum packaging, photorealistic product rendering, premium beauty products, 3D product rendering, hair care branding, product textures, realistic hair care mockups, 3D modeling services, beauty eCommerce, 3D product visualization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/anthi-naturals/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                <h1 className="services_title ras_title mb-0"><span>Anthi</span> <br /> <span className="text_black">Realistic 3D Serum <br /> Rendering</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/anthi/anthi1.jpg"
                        alt="Anthi" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Anthi is a hair care brand specializing in natural, restorative solutions for thinning hair. The project focused on creating realistic 3D renderings, product modeling, and mockups for their signature thinning hair serum bottle, aimed at enhancing their product presentation and visual appeal. Anthi needed high-quality, photorealistic visuals to showcase their thinning hair serum bottle and emphasize its premium design, natural ingredients, and effectiveness.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>3D Rendering</li>
                                            <li>Product Modeling</li>
                                            <li>Product Visualization</li>
                                            <li>Mockups</li>                                            
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Hair Care</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/anthi/anthi2.jpg" className="w-100" alt="Anthi" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/anthi/anthi3.jpg" className="w-100" alt="Anthi" />
                                </div>
                            </div>                            
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/anthi/anthi4.jpg" className="w-100" alt="Anthi" />
                        </div>
                        <div className="content_space">
                            <p className="services_content text_black">We created detailed 3D renderings and product models of the thinning hair serum bottle. Using advanced techniques in 3D modeling and rendering, we designed realistic textures and mockups that reflected the product's natural, high-end appeal. The focus was on achieving lifelike realism, bringing out the clarity of the bottle’s design and highlighting its premium look and feel. The 3D renderings helped Anthi improve its product presentation and boost the serum's visual appeal online. The lifelike visuals elevated the brand's premium image, enhancing its marketing materials and online listings for better customer engagement and product conversion.</p>
                        </div>
                        <div className="row mb-5 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/anthi/anthi5.jpg" className="w-100" alt="Anthi" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/anthi/anthi6.jpg" className="w-100" alt="Anthi" />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}