import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioWeightManage() {

    return (
        <>
            <Helmet>
                <title>Weight Management Fit | Wellness E-Commerce Case Study | aBox Agency</title>
                <meta name="title" content="Weight Management Fit | Wellness E-Commerce Case Study | aBox Agency" />
                <meta name="description" content="Discover how aBox Agency transformed Weight Management Fit’s online presence with a tailored e-commerce solution. Our custom UI/UX design, advanced website development, and striking branding enhance their wellness products. Explore the revamped site for a standout weight management experience." />
                <meta name="keywords" content="Weight Management Fit, Shopify website design, Shopify development, custom UI/UX design, eCommerce branding, 3D product rendering, Shopify store development, website graphics, label design, wellness product design, Shopify solutions, weight management products, innovative web design, online store branding, Shopify experts, responsive web design, user experience design, Shopify eCommerce, digital branding, website transformation, product visualizations, Shopify themes, brand identity, Shopify customization, web development services, wellness industry website, digital marketing for wellness, Shopify store enhancements, engaging website design, Shopify web design services, custom Shopify sites, dynamic website development, weight management website, Shopify portfolio" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/weight-manage/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/weight_manage.jpg" />
            </Helmet>

            <Header />

            <div className="page_bg bg_weight_manage">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Weight Manage</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1731564239.mp4" type="video/mp4" />
                                    </video>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod omsweets_text">Published in 2024</p>
                                    <h1 className="services_title weight_text mb-0">
                                        Revolutionizing
                                        <span className="weight_title"> Weight Management</span> <br />
                                        with Design
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content weight_text mb-3">Looking to help your weight management brand stand out in a crowded market?  aBox partnered with Weight Management Fit, a rising startup in the weight loss industry, to craft a winning digital presence. We completely transformed their brand identity and online experience, empowering them to achieve lasting success.</p>
                                    <p className="services_content weight_text">In the first phase, we developed a strong brand voice and visual identity that resonates with their target audience. This included creating a captivating website design and seamless development, ensuring a user-friendly platform that fosters trust and encourages conversions.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://weightmanage.fit/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="weight_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list weight_manage_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="weight_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list weight_manage_list">
                                                <li>Weight Management Products</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_weight_logo">
                                <img src="/assets/image/portfolios/weight_manage/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/weight_manage/weight1.webp" className="w-100" alt="Weight Manage" />
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight2.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight3.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title weight_title">Complete Branding and Design for Wellness Startup</h2>
                                    <p className="services_pera weight_text font_regular mb-0">
                                        We built a fully customized Shopify website for Weight Management Fit, including UI/UX design, website development, 3D product renderings, and unique label designs. Our work created a distinctive brand presence in the weight management sector.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight4.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight5.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight6.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight7.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight8.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight9.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight10.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight11.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gaps_columns gap_20 mt_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight12.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight13.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight14.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight15.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight16.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title weight_title">Boosted Visibility and Market Leadership Achieved</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera weight_text mb-0">
                                        Weight Management Fit saw a major boost in brand presence and market engagement with our design and branding solutions. Their revamped website has successfully attracted a wider audience. Visit <a href="https://weightmanage.fit/" target="_blank" className="official_link omsweets_text">www.weightmanage.fit</a> Fit to see more.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/weight_manage/weight17.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/weight_manage/weight18.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight19.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight20.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/weight_manage/weight21.webp"
                                        alt="Weight Manage" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title weight_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/weight_manage/weight22.webp" className="w-100" alt="weight Manage color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                {/* <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/yogathiya/yogathiya15.webp" alt="weight manage font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="weight_text perform_pera">From branding to web development, aBox has been phenomenal. The new website and social media presence capture the essence of our Namkeen and savory products perfectly. Their creative approach has significantly boosted our online visibility.</p>
                                    <h3 className="title_color padd_s weight_title">Sagar CEO Founder</h3>
                                    <p className="font_medium services_pera weight_text mb-0 padd_s">Founder of Yo Gathiya</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg agarwal_sweets_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/agarwalbhavan-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}