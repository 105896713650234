import React from 'react'

export default function StarIcon() {
      return (
            <>
                  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={22} viewBox="0 0 23 22" fill="none">
                        <path d="M11.8535 0.33252L14.4354 8.27882H22.7907L16.0311 13.1899L18.613 21.1362L11.8535 16.2251L5.09399 21.1362L7.6759 13.1899L0.916366 8.27882H9.2716L11.8535 0.33252Z" fill="#181818" />
                  </svg>
            </>
      )
}