import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioShistaka() {

    return (
        <>
            <Helmet>
                <title>Shistaka's Herbal Elegance | Portfolio Tea Brand: Shistaka | aBox Agency</title>
                <meta name="title" content="Shistaka's Herbal Elegance | Portfolio Tea Brand: Shistaka | aBox Agency" />
                <meta name="description" content="Immerse yourself in the world of Shistaka's premium herbal teas, thoughtfully curated by the digital wizards at DigitalCraft Web Studio. Explore Shistaka's unique journey and discover how our web solutions have brought their herbal elegance to life online." />
                <meta name="keywords" content="Shistaka, Premium Herbal Teas, Herbal Infusions, Artisanal Blends, Web Portfolio, Creative Showcase, Tea Collection, Health and Wellness, Handcrafted Brews, Tea Accessories, Herbal Elegance, Sustainable Sips, DigitalCraft Studio, Online Presence, E-commerce, Web Agency, Web Development, E-commerce Website, A/B Testing, Success Stories, Client Showcase, WebCraft Studios, Responsive Web Design, E-commerce Integration, User-Friendly Experience, Mobile-Optimized Website" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/shistaka/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_shistaka_p">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">shistaka</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1703828815.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-10 col-lg-9 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2019 / 2023</p>
                                    <h1 className="services_title text-white mb-0">
                                        Shistaka <br />
                                        <span className="text_shiska">
                                            Elevating Your Tea Experience <br />
                                            with Exquisite Blends
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Shistaka, a health-focused brand, offers gut-friendly recipes and products. They aim to go global via online sales but lack knowledge about enhancing their brand and online presence. aBox provided comprehensive consultation, guiding them through the process. They sought aBox's services, including design, web development, product labeling, and other online services. With aBox's support, Shistaka has elevated its brand and offerings.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Tea, health and wellness Products</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_shiska_logo">
                                <img src="/assets/image/portfolios/shistaka/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/shistaka/shistaka2.webp" className="w-100" alt="shistaka" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting the perfect foundation for a delightful brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our tech experts put in their best efforts to ensure Shistaka's vision comes to life with precision, customizing every feature to meet their unique needs. We've created a seamless online shopping experience with an easy-to-use interface, showcasing our diverse range of delightful tea offerings.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/shistaka/shistaka3.webp"
                                            alt="shistaka" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/shistaka/shistaka4.webp"
                                            alt="shistaka" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/shistaka/shistaka5.webp"
                                            alt="shistaka" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/shistaka/shistaka6.webp"
                                            alt="shistaka" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/shistaka/shistaka7.webp"
                                    alt="shistaka" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_shiska_logo">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/shistaka/shistaka8.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/shistaka/shistaka9.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/shistaka/shistaka10.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Enhancing the essence of the brand with finesse</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Shistaka offers a delightful journey through the world of herbal and flavoured teas, combining tradition with modern technology. Visit <a href="" className="text-white portfolio_a">shistaka.com</a> for a harmonious blend of taste and sophistication.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/shistaka/shistaka11.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/shistaka/shistaka12.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/shistaka/shistaka13.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/shistaka/shistaka14.webp"
                                        alt="shistaka" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/shistaka/shistaka15.webp"
                                    alt="shistaka" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/shistaka/shistaka16.webp" className="w-100" alt="shistaka color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/shistaka/shistaka17.webp" alt="shistaka font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 className="title_color padd_s text_shiska">Aadhya Tulisan and Janvi tulsian</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Shistaka </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg sportsaber_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/sportsaber" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}