import React from 'react'

export default function RightArrow() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} viewBox="0 0 16 13" fill="none">
                <path d="M0 6.33252H14M14 6.33252L8.53659 0.83252M14 6.33252L8.53659 11.8325" stroke="black" strokeWidth="1.5" />
            </svg>
        </>
    )
}