import React from 'react'

export default function ShopifyLogo() {
      return (
            <>
                  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={32} viewBox="0 0 28 32" fill="none">
                        <path opacity="0.8" d="M23.9597 6.30954C23.9413 6.14456 23.7947 6.07123 23.6847 6.0529C23.5747 6.03457 21.3749 6.01623 21.3749 6.01623C21.3749 6.01623 19.5234 4.21972 19.3401 4.0364C19.1567 3.85308 18.8084 3.90808 18.6618 3.94474C18.6618 3.94474 18.3135 4.05473 17.7269 4.23805C17.6352 3.92641 17.4885 3.54144 17.2869 3.15647C16.6269 1.89158 15.6737 1.23163 14.5005 1.23163C14.4271 1.23163 14.3355 1.23163 14.2621 1.24996C14.2255 1.2133 14.1888 1.17664 14.1522 1.12164C13.6389 0.571688 12.9972 0.315043 12.2273 0.333374C10.7241 0.370038 9.23923 1.45161 8.011 3.39479C7.1494 4.75134 6.50779 6.4562 6.32447 7.77609C4.60128 8.30771 3.39138 8.67435 3.37305 8.69268C2.51146 8.96765 2.47479 8.98599 2.3648 9.81092C2.27314 10.4159 0 28.0327 0 28.0327L19.0834 31.3325L27.3511 29.2793C27.3511 29.2793 23.978 6.4562 23.9597 6.30954ZM16.7736 4.53136C16.3336 4.65968 15.8387 4.82467 15.2887 4.98965C15.2704 4.23805 15.1787 3.1748 14.8304 2.25821C15.9853 2.4782 16.5353 3.76142 16.7736 4.53136ZM14.2988 5.30129C13.2906 5.61293 12.209 5.94291 11.1091 6.29121C11.4207 5.11798 12.0073 3.94474 12.7223 3.1748C12.9972 2.88149 13.3639 2.56985 13.8038 2.38654C14.2255 3.24813 14.2988 4.47636 14.2988 5.30129ZM12.2456 1.34162C12.594 1.34162 12.8873 1.41495 13.1439 1.57994C12.7406 1.78159 12.3373 2.09323 11.9707 2.49653C11.0174 3.52311 10.2841 5.09964 9.99083 6.63952C9.07424 6.91449 8.19432 7.18947 7.36939 7.44611C7.91934 5.02632 9.93584 1.41495 12.2456 1.34162Z" fill="#417B5A" />
                        <path opacity="0.6" d="M23.6872 6.053C23.5772 6.03467 21.3774 6.01634 21.3774 6.01634C21.3774 6.01634 19.5259 4.21982 19.3426 4.0365C19.2693 3.96318 19.1776 3.92651 19.0859 3.92651V31.3326L27.3536 29.2794C27.3536 29.2794 23.9805 6.47464 23.9622 6.30965C23.9255 6.14466 23.7972 6.07134 23.6872 6.053Z" fill="#417B5A" />
                        <path d="M14.5007 10.306L13.5474 13.899C13.5474 13.899 12.4842 13.404 11.2009 13.4957C9.3311 13.6057 9.31277 14.7789 9.3311 15.0906C9.44109 16.7038 13.6574 17.0521 13.8957 20.8101C14.079 23.7798 12.3192 25.7963 9.7894 25.9613C6.74632 26.1446 5.07812 24.3665 5.07812 24.3665L5.71974 21.635C5.71974 21.635 7.40626 22.8999 8.74449 22.8266C9.62441 22.7716 9.93605 22.0566 9.89939 21.5434C9.77107 19.4535 6.32469 19.5635 6.10471 16.1355C5.92139 13.239 7.8279 10.306 12.0259 10.031C13.6757 9.88433 14.5007 10.306 14.5007 10.306Z" fill="white" />
                  </svg>
            </>
      )
}