import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelRasLipstick() {

      return (
            <>
                  <Helmet>
                        <title>Luxurious 3D Rendering for Ras Luxury | aBox Agency</title>
                        <meta name="title" content="Luxurious 3D Rendering for Ras Luxury | aBox Agency" />
                        <meta name="description" content="aBox Agency created photorealistic 3D renderings and product visualisations for Ras Luxury's eco-friendly cosmetics. We showcased the brand's elegant design, sustainability commitment, and premium design." />
                        <meta name="keywords" content="aBox Agency, 3D rendering, photorealistic 3D models, product visualisation, product modelling, luxury cosmetics, eco-friendly beauty, vegan-friendly makeup, sustainable branding, premium beauty products, 3D product design, luxury beauty visuals, 3D texturing, material design, eCommerce product visuals, Ras Luxury, 3D rendering agency, sustainable cosmetics visuals, photorealistic product images, luxury cosmetics branding, premium 3D visuals, eco-conscious beauty design, luxury beauty rendering, high-quality 3D modelling, 3D cosmetics visualisation, aBox Agency services, 3D product rendering, beauty product visualisation." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/ras-lipstick/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>RAS Luxurious </span> <br /> <span className="text_black">3D Rendering</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/ras/ras9.jpg" alt="Ras" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">At Ras Luxury Cosmetics, luxury meets sustainability with their high-quality, vegan makeup. Our task was to create 3D rendering and product modelling that showcases their premium lipstick collection. Using advanced 3D rendering techniques, we made sure to highlight every detail of the lipstick packaging.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>3D Rendering</li>
                                                                  <li>Product Modeling</li>
                                                                  <li>Product Visualization</li>
                                                                  <li>Texturing and Material Design</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Luxury Beauty & Cosmetics</li>
                                                                  <li>E-commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <video className="h-100" width={"100%"} autoPlay loop muted playsInline>
                                                      <source src="https://d1cihhbb125h48.cloudfront.net/1736342824.mp4" type="video/mp4" />
                                                </video>
                                          </div>
                                          <div className="col-md-6">
                                                <div className="h-100">
                                                      <img src="/assets/image/label/ras/ras10.jpg" className="h-100 w-100" alt="Ras" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/ras/ras11.jpg" className="w-100" alt="Ras" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/ras/ras12.jpg" className="w-100" alt="Ras" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">At Ras Luxury Cosmetics, luxury meets sustainability with their high-quality, vegan makeup. Our task was to create 3D rendering and product modelling that showcases their premium lipstick collection. Using advanced 3D rendering techniques, we made sure to highlight every detail of the lipstick packaging.</p>
                                    </div>
                                    <div className="">
                                          <img src="/assets/image/label/ras/ras13.jpg" className="w-100" alt="Ras" />
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/ras/ras14.jpg" className="w-100" alt="Ras" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div className="gap_remove">
                                                      <img src="/assets/image/label/ras/ras15.jpg" className="w-100" alt="Ras" />
                                                </div>
                                          </div>
                                    </div>

                                    <div className="content_space">
                                          <p className="services_content text_black">This product visualisation helped Ras Luxury enhance its brand image, making their eco-conscious products stand out in the market. Through photorealistic 3D rendering, we created a visual experience that resonated with their audience and brought their luxury products to life.</p>
                                    </div>

                                    <div className="mb-4">
                                          <img src="/assets/image/label/ras/ras16.jpg" className="w-100" alt="Ras" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}