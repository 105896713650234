import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioBhagavadGita() {

    return (
        <>
            <Helmet>
                <title>Krsna Consciousness Website | Custom Web Design & Website | aBox Agency</title>
                <meta name="title" content="Krsna Consciousness Website | Custom Web Design & Website | aBox Agency" />
                <meta name="description" content="Discover how we transformed Krsna Consciousness into a captivating online experience. Our custom Shopify store and UI/UX design bring sacred commerce to life. Explore the project and see how we blended spirituality with digital innovation." />
                <meta name="keywords" content="Krsna Consciousness, Custom Shopify Website, UI/UX Design, Figma Design, Sacred Books Presentation, Bhagavad Gita, Science of Self-Realization, Shopify Development, aBox Agency, Book Showcase, Web Design, E-commerce Solutions, UI/UX Development, Custom Web Design, Book Presentation, Krsna Consciousness Website, Shopify UI/UX, Web Development Agency, Sacred Texts, Online Book Display, Bhagavad Gita Presentation, Spiritual Books, Custom Shopify Design, User Experience Design, Book Collection Display, Figma UI Design, E-commerce Development, UI/UX Solutions, Krsna Consciousness Branding, Web Design Portfolio, Online Book Store" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/bhagavad-gita/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/bhagavad_gita.jpg" />
            </Helmet>

            <Header />

            <div className="page_bg bg_bhagavad_gita">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Bhagavad Gita</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title bhagavad_gita_text mb-0">
                                        Bhagavad-Gita <br />
                                        <span className="bhagavad_gita_title">
                                            Divine e-commerce, Digital Dharma
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content bhagavad_gita_text mb-3">A custom Shopify store was crafted for Krsna Consciousness, blending e-commerce functionality with a unique brand identity. The UI/UX design, meticulously created on Figma, ensures a seamless user experience.</p>
                                    <p className="services_content bhagavad_gita_text">The store showcases a wide range of spiritual products, facilitating easy navigation and purchase. The design harmoniously reflects the brand's philosophy, creating a spiritually immersive shopping environment.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="#" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="bhagavad_gita_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list bagavad_gita_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="bhagavad_gita_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list bagavad_gita_list">
                                                <li>Sacred Books Seller</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_bhagavad_logo">
                                <img src="/assets/image/portfolios/bhagavad_gita/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita1.webp" className="w-100" alt="Bhagavad Gita" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title bhagavad_gita_title">Book Presentation Design, Sacred Texts, Digital Form</h2>
                                    <p className="services_pera bhagavad_gita_text font_regular mb-0">
                                        The design transforms timeless scriptures like Bhagavad Gita, Science of Self-Realization, and PQPA into visually appealing digital formats. The layout enhances Covore and complements the sacred content.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita2.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita3.webp"
                                            alt="Bhagavad Gita" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita4.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita5.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita6.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                </div>
                                <div className="aumex pb-0 sweet_delect bhagavad_banner_bg mt_20">
                                    <div className="gaps gap_20">
                                        <div>
                                            <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita7.webp"
                                                alt="Bhagavad Gita" className="img-fluid w-100" />
                                        </div>
                                        <div>
                                            <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita8.webp"
                                                alt="Bhagavad Gita" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title bhagavad_gita_title">Improved Online Engagement for Consciousness</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera bhagavad_gita_text mb-0">
                                        The custom Shopify store effectively communicates the brand's message, driving engagement and sales. The book presentations have garnered positive feedback for their aesthetic appeal and user-friendliness.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita9.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita10.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita11.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita12.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita13.webp"
                                        alt="Bhagavad Gita" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title bhagavad_gita_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita14.webp" className="w-100" alt="bhagavad gita color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/bhagavad_gita/bhagavad_gita15.webp" alt="bhagavad gita font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="bhagavad_gita_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s bhagavad_gita_title">AuroLab</h3>
                                    <p className="font_medium services_pera bhagavad_gita_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg weight_manage_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/weight-manage" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}