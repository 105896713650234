import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function UILycanPay() {

    return (
        <>
            <Helmet>
                <title>Lycan Pay UI Design | UI/UX Design Portfolio | aBox Agency</title>
                <meta name="title" content="Lycan Pay UI Design | UI/UX Design Portfolio | aBox Agency" />
                <meta name="description" content="Explore Lycan Pay, an innovative e-commerce payments solution developed by aBox Agency. Learn more about our UI/UX design, visual identity, and web development expertise in creating seamless payment experiences." />
                <meta name="keywords" content="e-commerce, online payments, seamless experiences, abox, abox agency, Shopify Shopify UIUX, user interface design, user experience, web development, visual identity, aBox Agency, Lycan Pay, payment, UI, UX, UIUX, UI/UX, User experience solutions, e-commerce solutions, digital design, innovative solutions, creative portfolio, online store, payment processing, digital commerce, digital payments, transaction security, customer experience, interactive design, mobile compatibility, payment gateways, financial technology, online transactions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ui/lycanpay/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/lycan_pay.jpg" />
            </Helmet>

            <Header />

            <div className="main_page lycan_bg">
                <div className="product_banner">
                    <img src="/assets/image/ui/lycanpay/lycanpay1.webp"
                        alt="lycanpay" className="img-fluid w-100" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/lycanpay/lycanpay2.webp"
                        alt="lycanpay" className="img-fluid w-100" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/lycanpay/lycanpay3.webp"
                        alt="lycanpay" className="img-fluid w-100" />
                </div>

                <div className="mt_30">
                    <img src="/assets/image/ui/lycanpay/lycanpay4.webp" className="w-100" alt="lycanpay" />
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/lycanpay/lycanpay5.webp" className="w-100" alt="lycanpay" />
                </div>

                <div className="mt_20 lycan_blog">
                    <div className="row mx-0">
                        <div className="col-md-6">
                            <div>
                                <img src="/assets/image/ui/lycanpay/lycanpay6.webp" className="w-100" alt="lycanpay" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <img src="/assets/image/ui/lycanpay/lycanpay7.webp" className="w-100" alt="lycanpay" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt_20">
                    <img src="/assets/image/ui/lycanpay/lycanpay8.webp" className="w-100" alt="lycanpay" />
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}