import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function LabelweightManage() {

      return (
            <>
                  <Helmet>
                        <title>Custom Packaging Design for Weight Manage | aBox Agency</title>
                        <meta name="title" content="Custom Packaging Design for Weight Manage | aBox Agency" />
                        <meta name="description" content="Abox Agency created innovative packaging design for Weight Manage’s weight management supplements, featuring sleek, modern, and eye-catching design to enhance brand visibility." />
                        <meta name="keywords" content="Packaging design, supplement packaging, box design, weight management supplements, product branding, health and wellness packaging, graphic design, custom packaging, product label design, e-commerce packaging, wellness product design, brand identity, modern packaging, supplement box design, product packaging mockup, innovative product design, product presentation, weight loss supplement design, health supplement branding, supplement product design, fitness packaging, weight management brand, clean packaging design, product visibility, health product branding, product showcase, custom box design, supplement brand identity." />
                        <link rel="canonical" href="https://www.abox.agency/portfolio/label/weight-manage/" />
                  </Helmet>

                  <Header />

                  <div className="bg-white">
                        {/* hero section */}
                        <div className="hero_padding section_detail">
                              <div className="container">
                                    <div className="row">
                                          <div className="col-xxl-6 col-lg-8 col-md-10">
                                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                                <h1 className="services_title ras_title mb-0"><span>Weight Manage</span> <br /> <span className="text_black">Designing the Future <br /> of Supplement Packaging</span></h1>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* Product Section */}
                        <div className="product_banner">
                              <img src="/assets/image/label/weight_manage/weightmanage1.jpg"
                                    alt="weight manage" className="img-fluid w-100" />
                        </div>

                        {/* detail section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="row justify-content-between">
                                          {/* <div className="vr_border"></div> */}
                                          <div className="col-xxl-5 col-xl-6 pad_right">
                                                <p className="services_content text_black">Weight Manage offers effective weight management supplements, and we had the privilege of working with them to design the packaging for their oval supplement box. The goal was to create a sleek and modern design that would resonate with their target audience while reflecting the brand’s commitment to health and wellness. They needed a packaging design that stood out on shelves and online, effectively showcasing their product’s benefits and premium quality.</p>
                                          </div>
                                          <div className="col-xxl-6 col-xl-5">
                                                <div className="row">
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Services</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Packaging Design</li>
                                                                  <li>Product Branding</li>
                                                                  <li>Box Design</li>
                                                                  <li>3D Label Design</li>
                                                            </ul>
                                                      </div>
                                                      <div className="ui_list_border"></div>
                                                      <div className="col-md-6">
                                                            <p className="text_black services_menu">Industries</p>
                                                      </div>
                                                      <div className="col-md-6">
                                                            <ul className="services_menu_list ui_service_list">
                                                                  <li>Health & Wellness</li>
                                                                  <li>E-Commerce</li>
                                                            </ul>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                        {/* view section */}
                        <div className="ui_section">
                              <div className="container">
                                    <div className="mt_30">
                                          <img src="/assets/image/label/weight_manage/weightmanage2.jpg" className="w-100" alt="Weight Manage" />
                                    </div>
                                    <div className="row mt_30 gap-md-0 gap-4">
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/weight_manage/weightmanage3.jpg" className="w-100" alt="Weight Manage" />
                                                </div>
                                          </div>
                                          <div className="col-md-6">
                                                <div>
                                                      <img src="/assets/image/label/weight_manage/weightmanage4.jpg" className="w-100" alt="Weight Manage" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="content_space">
                                          <p className="services_content text_black">We developed a comprehensive packaging design for the oval box, focusing on clean lines, vibrant colors, and product messaging that highlighted the brand’s core values. The final design was both eye-catching and informative, ensuring the packaging not only represented the brand's high-quality supplements but also made a lasting impression on potential customers. Our work helped Weight Manage boost their brand’s presence in the competitive wellness market.</p>
                                    </div>
                                    <div className="mb-4">
                                          <img src="/assets/image/label/weight_manage/weightmanage5.jpg" className="w-100" alt="Weight Manage" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <LetsTalk />

                  <Footer />
            </>
      );
}