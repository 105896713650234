import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioNeoRoots() {

    return (
        <>
            <Helmet>
                <title>Neo Roots Green Masterpiece | Supportive Freenary for One | aBox Agency</title>
                <meta name="title" content="Neo Roots Green Masterpiece | Supportive Freenary for One | aBox Agency" />
                <meta name="description" content="Step into the enchanting world of Neo Roots, a green paradise meticulously sculpted by the digital artisans at aBox Agency. Experience the seamless blend of digital innovation and nature’s splendor as we elevate Neo Roots’ online presence, showcasing their mastery in transforming spaces into lush, green sanctuaries." />
                <meta name="keywords" content="Neo Roots, aBox, Web Agency, Web Development, User Research, Project Management, Brand Design, Interface Design, Interaction Design, Design System, Multimedia Design, Front-End Development, Back-End Development, Brand Identity, Full Website Development, UIUX, CMS, SEO, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Landscape Design, Indoor Spaces, Outdoor Spaces, Green Sanctuaries, Success Stories, Web Design, Creative Solutions, aBox Agency, Functional Green Sanctuaries, Online Portfolio, Web Development, Digital Marketing, Responsive Design, SEO Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/neo-roots/" />
            </Helmet>

            <Header />

            <div className="page_bg neoroots_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name paper_text_bg text_light">Neo Roots</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1710325896.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name paper_text_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">Neo Roots <br /> <span>Blossoming Beauty: Neo Roots' Wonderland by aBox</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Green Paradise by aBox Step into the captivating world of Neo Roots, brought to life by aBox’s innovative web design. Our digital craftsmen have woven an immersive tapestry where the digital and the natural blend flawlessly. Discover the craftsmanship of premium landscape design through an intuitive interface, beckoning you to explore Neo Roots’ verdant retreats. Discover the balance of design and nature, meticulously assembled by aBox for a seamlessly enjoyable journey.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://neoroots.design/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>User Research</li>
                                                <li>Project Management</li>
                                                <li>Brand Design</li>
                                                <li>Interface Design</li>
                                                <li>Interaction Design</li>
                                                <li>Design System</li>
                                                <li>Multimedia Design</li>
                                                <li>Front-End Development</li>
                                                <li>Back-End Development</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Brand Identity</li>
                                                <li>Full Website Development</li>
                                                <li>UIUX</li>
                                                <li>CMS</li>
                                                <li>SEO</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/neoroots/neoroots1.webp" className="w-100" alt="Neo Roots" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Laying the Groundwork for Neo Roots' Flourishing Identity</h2>
                                    <p className="services_pera text_light font_regular mb-0">The adept artisans at aBox have devoted their skills to actualizing Neo Roots’ dream, customizing each component to meet their unique needs. Our team has carved out an easy-to-navigate online platform, guaranteeing a seamless exploration of Neo Roots’ lush landscape creations. Glide smoothly through a user-friendly portal website, revealing a range of green havens that transform indoor and outdoor spaces with practical elegance.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-0">
                                <div className="d-flex justify-content-between flex-column gap_10">
                                    <img src="/assets/image/portfolios/neoroots/neoroots2.webp" className="w-100" alt="Neo Roots" />
                                    <img src="/assets/image/portfolios/neoroots/neoroots3.webp" className="w-100" alt="Neo Roots" />
                                    {/* <img src="/assets/image/portfolios/neoroots/neoroots4.webp" className="w-100" alt="Neo Roots" /> */}
                                </div>
                            </div>
                            <div className="aumex strenth_bg pb-0 sweet_delect bg-white mt_30">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/neoroots/neoroots4.webp"
                                        alt="Neo Roots" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/neoroots/neoroots5.webp"
                                        alt="Neo Roots" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Elevating the Soul of Neo Roots with Artistry</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">On a transformative journey with Neo Roots, connoisseurs of high-end landscape design. Fusing ageless grace with avant-garde implementation, Neo Roots morphs indoor and outdoor spaces into functional green sanctuaries. Dive into <a href="https://neoroots.design/" className="text-white portfolio_a" target="_blank">Neoroots.design</a>, where refinement meets practicality in the artistic arrangement of living spaces.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="d-flex justify-content-between flex-column border_img">
                                    <img src="/assets/image/portfolios/neoroots/neoroots6.webp" className="w-100" alt="Neo Roots" />
                                    <img src="/assets/image/portfolios/neoroots/neoroots7.webp" className="w-100" alt="Neo Roots" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Working with aBox for our company, Neo Roots, was a pleasure. Their professionalism and dedication were evident from the start. They grasped our brand's essence and translated it into a modern, sleek web design that perfectly represents our custom-greenery business. The website's functionality and user-friendliness have garnered positive feedback, thanks to aBox's top-notch development skills. We highly recommend aBox for their exceptional services.</p>
                                    <h3 className="text_shiska padd_s">Pooja</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Neo Roots Design</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg within_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/within-beauty" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}