import React, { useState } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {

    return (
        <>
            <Helmet>
                <title>Privacy Policy | Transparent Web Development Practices | aBox Agency</title>
                <meta name="title" content="Privacy Policy | Transparent Web Development Practices | aBox Agency" />
                <meta name="description" content="Explore aBox's Privacy Policy to understand how we collect, use, and protect client and user information in our web development services. Learn about data security, third-party collaborations, marketing practices, and your rights. Contact us for any privacy-related queries." />
                <meta name="keywords" content="aBox Agency, Terms and Conditions, Legal, Website Policies, User Agreement, Service Terms, Privacy Policy, Acceptance, User Responsibilities, Legal Compliance, Online Services, Governing Laws, Agreement Details, Web Terms, Online Terms, Conditions, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B" />
                <link rel="canonical" href="https://www.abox.agency/privacy-policy/" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="section_detail sectin_branding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="portfolio_heading font_medium">Privacy Policy</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* Branding Date Section */}
            <div className="section_date">
                <div className="container">
                    <div className="branding_date d-flex align-items-center">
                        <span className="contact_span">Last Updated </span>
                        <span className="date_border"></span>
                        <span className="color_dark">November 1, 2023.</span>
                    </div>
                </div>
            </div>

            {/* Legal services List */}
            <div className="section_legal">
                <div className="container">
                    <div className="row seo_border legal_content">
                        <div className="col-xl-6 branding_padding pt-0">
                            <h2 className="service_subheading color_dark">Introduction</h2>
                        </div>
                        <div className="col-xl-6 branding_padding pt-0 seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">Welcome to aBox! This comprehensive privacy policy outlines how aBox, acting as the data controller, collects, processes, and protects your personal data. We are committed to ensuring the lawful, fair, and transparent handling of your information. Please carefully review this policy to understand your rights and how to exercise them.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Collection and Use of <br className="d-xl-block d-none" /> Client Information</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <div className="seo_pb">
                                    <h3 className="legal_title">Information Collected</h3>
                                    <p className="seo_content mb-2 color_dark">When you engage with aBox for web development services, you may provide the following client information:</p>
                                    <ul className="privacy_list">
                                        <li><span className="font_medium">Contact Information:</span> full name, email address, and phone number.</li>
                                        <li><span className="font_medium">Company Details:</span> Company name, physical address, industry.</li>
                                        <li><span className="font_medium">Project Specifics:</span> Detailed project requirements, objectives, and specifications.</li>
                                        <li><span className="font_medium">Billing Information:</span> Invoicing details, payment preferences.</li>
                                    </ul>
                                </div>
                                <div className="seo_pb">
                                    <h3 className="legal_title">Purpose of Collection</h3>
                                    <p className="seo_content mb-2 color_dark">This information is collected for the explicit purpose of delivering tailored web development services.</p>
                                    <ul className="privacy_list">
                                        <li>Facilitating communication and collaboration throughout the project lifecycle.</li>
                                        <li>Ensuring effective internal project management, documentation, and quality assurance.</li>
                                        <li>Managing invoicing, financial transactions, and project-related correspondence.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="legal_title">Data Access and Confidentiality</h3>
                                    <p className="seo_content mb-0 color_dark">Access to client information is restricted to authorized personnel directly involved in the development process. We adhere to strict confidentiality standards, ensuring that project details and client information remain private and are not disclosed without explicit client consent.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Collection and Use of User <br className="d-xl-none d-block" /> Information (Website Visitors)</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <div className="seo_pb">
                                    <h3 className="legal_title">Information Collected</h3>
                                    <p className="seo_content mb-2 color_dark">For individuals visiting our website, we collect non-personal information, including:</p>
                                    <ul className="privacy_list">
                                        <li><span className="font_medium">Analytics Data:</span> IP address, browser details, device type, and usage patterns.</li>
                                        <li><span className="font_medium">Cookies:</span> information stored on the user's device for an enhanced browsing experience.</li>
                                    </ul>
                                </div>
                                <div className="seo_pb">
                                    <h3 className="legal_title">Purpose of Collection</h3>
                                    <p className="seo_content mb-2 color_dark">User information is collected for the following purposes:</p>
                                    <ul className="privacy_list">
                                        <li>analyzing website traffic, trends, and user behavior for continual improvement.</li>
                                        <li>Personalizing the user experience and providing targeted content.</li>
                                        <li>Enhancing website security and preventing unauthorized access.</li>
                                    </ul>
                                </div>
                                <div className="seo_pb">
                                    <h3 className="legal_title">Cookies</h3>
                                    <p className="seo_content mb-0 color_dark">Our website employs session and persistent cookies to enhance the user experience. Users can manage cookie preferences through their browser settings. Please note that certain functionalities may be affected if cookies are disabled.</p>
                                </div>
                                <div>
                                    <h3 className="legal_title">Data Security</h3>
                                    <p className="seo_content mb-2 color_dark">We prioritize the security of client and user data through:</p>
                                    <ul className="privacy_list">
                                        <li><span className="font_medium">Encryption:</span> Secure data transmission using industry-standard encryption protocols.</li>
                                        <li><span className="font_medium">Access Controls:</span> Limited access to client information based on unique credentials.</li>
                                        <li><span className="font_medium">Regular Audits:</span> Periodic security audits to identify and address potential vulnerabilities.</li>
                                        <li><span className="font_medium">Data Backups:</span> Regularly back up data to prevent data loss.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Third-Party <br className="d-xl-block d-none" /> Collaboration</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">During web development projects, aBox may collaborate with third-party tools, service providers, or consultants. Any third-party involvement adheres to stringent data protection standards, and clients are informed of such collaborations.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Marketing</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">aBox may use your personal data for marketing purposes based on legitimate interests. You can decline marketing efforts directly or by contacting aBox. Consent will be sought when necessary, and you can withdraw it at any time.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Retention of Personal <br className="d-xl-block d-none" /> Data</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">aBox will retain your personal data for as long as necessary to initiate, maintain, or administrate a business-related contact with you or the organization you represent. Marketing-related data may be stored for up to a year post-contractual relationship unless explicitly declined.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Transfer of Personal Data</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-2 color_dark">aBox only transfers personal data as described below and takes appropriate security measures. Disclosures may occur</p>
                                <ul className="privacy_list">
                                    <li>When required by laws and regulations.</li>
                                    <li>To service providers within the aBox Group, or its advisers, agents, and/or employees.</li>
                                    <li>When necessary to protect the rights and/or property of aBox.</li>
                                    <li>Your personal data is integral to aBox's operations, and no personal data is sold.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Your Rights</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">You have the right to request information about the processing of your personal data, request corrections or erasures, and object to processing. Data portability is also available in certain situations. For privacy and data protection, proper identification is required when exercising these rights.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Complaints</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">If you believe aBox's processing of your personal data is not in accordance with applicable laws, you have the right to file a complaint with the relevant data protection authority.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Cookies</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">aBox uses cookies for website optimization. Users can manage cookie preferences through browser settings.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding">
                            <h2 className="service_subheading color_dark">Changes to the <br className="d-xl-block d-none" /> Privacy Policy</h2>
                        </div>
                        <div className="col-xl-6 branding_padding seo_border">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">This privacy policy may be updated periodically. The latest version will be published on our website. In the case of material changes, we will email you if you have provided your email address.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 branding_padding pb-0">
                            <h2 className="service_subheading color_dark">Contact aBox</h2>
                        </div>
                        <div className="col-xl-6 branding_padding pb-0">
                            <div className="content_pad">
                                <p className="seo_content mb-0 color_dark">If you have questions or concerns about this privacy policy or wish to exercise your rights, please contact us at hi@abox.agency</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="branding_services">
                <div className="container">
                    <h2 className="legal_services">
                        Thank you for choosing <span>aBox Agency.</span> <br />For your web development needs.
                    </h2>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}